import React, { useState, useEffect } from "react";
import { loadScript } from "../../../../globals/constants";
import CardCheckoutForm from "./checkoutform";
import WithoutStripeMethod from "./withoutStripeMethod";
import { useSelector, useDispatch } from "react-redux";
import Toast from "../../toast";
import apiCall from "../../../../apicall";
import { userDetails } from "../../../../store/reducers/menu";
import TableLoader from "../../tableLoader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { logEvent_Analytics } from "../../firebase/firebase-analytics-service";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CardPaymentScreen = ({
  computeChargesDetails,
  setShowPaymentScreen,
  postJobFunction,
  postJobReqParam,
  handleHideJobType,
  formValues
}) => {
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "success",
  });
  const [paymentMethodCheckLoader,setPaymentMethodCheckLoader] = useState(false)
  const [userInfo, setUserInfo] = useState({})
  // const [clientSecret, setClientSecret] = useState();
  // const [achDetails, setAchDetails] = useState();

  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(() =>{
    logEvent_Analytics('payment_screen_web')
  },[])

  const { TOKEN } = useSelector(
    (state) => state.menu
  );
  const dispatch = useDispatch()

  

  console.log("userinfor ",userInfo)

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const checkPaymentMethodStatus =async () =>{
    try {
      setPaymentMethodCheckLoader(true);
      const accountResponse = await apiCall(
        "account",
        "GET",
        null,
        TOKEN
      );
      console.log("accountResponse-----",accountResponse)
      if (accountResponse?.data?.id && accountResponse?.status === 200) {
        setPaymentMethodCheckLoader(false);
        dispatch(userDetails(accountResponse?.data));
        setUserInfo(accountResponse?.data)
      } else {
        setPaymentMethodCheckLoader(false);
        displayErrMsg(accountResponse?.response?.data?.message);
      }
      setPaymentMethodCheckLoader(false);
    } catch (err) {
      // setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      setPaymentMethodCheckLoader(false);
    }
  }

  // const getClientSecret =async () =>{
  //   try{
  //     var postData = new FormData();
  //     postData.append("apiVersion", "2015-10-12");
  //     postData.append("amount", parseInt(computeChargesDetails?.totalCharges)); //must required to pass currency in cent to backend 
  //     postData.append("currency", "USD");
        
  
  //    const api = "institutions/stripe/ephemeralKeyAndIntentACH"
  //    const response = await apiCall(api, "POST", postData, TOKEN, true, false);
  //    setAchDetails(response)
  //   setClientSecret(response?.data?.paymentIntentSecret)
  //         console.log("client secret responseeee",response?.data?.paymentIntentSecret)
  //   }catch(err){
  //     console.log("client secret err",err)
  //   }
    
  // }

  useEffect(()=>{
    checkPaymentMethodStatus();
    // getClientSecret()
  },[])

  // console.log("stripe prokmisss",clientSecret)

  // const appearance = {
  //   theme: 'stripe',
  //   layout: "tabs",
  // };
  // const options = {
  //   clientSecret,
  //   appearance,
  // };

  return (
    // clientSecret && 
    // (
    <Elements stripe={stripePromise}>
    <div className="pricing-block-outer">
      <div className="row justify-content-center">
        <div className="col-md-2 p-table-highlight"></div>
        <div className="col-md-8 p-table-highlight m-b30">
          <div className="pricing-table-1 circle-yellow">
            {/* <div className="p-table-recommended">Recommended</div> */}
            <div className="p-table-title">
              <h4 className="wt-title">Grand Total</h4>
            </div>
            <div className="p-table-inner">
              <div className="p-table-price">
                <span>
                  ${(computeChargesDetails?.totalCharges * formValues.noOfCandidates / 100).toFixed(2)}
                </span>
                {/* <p>Monthly</p> */}
              </div>
                <div className="p-table-list">
                  {formValues.noOfCandidates > 1 && (<div>
                    <h4>Per Worker Charges</h4>
                    <ul>
                      <li>
                        <i className="feather-check" />
                        Booking and Processing Fee: $
                        {(
                          computeChargesDetails?.bookingAndProcessingFee / 100
                        ).toFixed(2)}
                      </li>
                      <li>
                        <i className="feather-check" />
                        Service Fee: $
                        {(computeChargesDetails?.serviceFee / 100).toFixed(2)}
                      </li>
                      <li>
                        <i className="feather-check" />
                        Salary: ${(computeChargesDetails?.salary / 100).toFixed(2)}
                      </li>
                      <h4>Total = ${((
                        (computeChargesDetails?.bookingAndProcessingFee / 100) +
                        (computeChargesDetails?.serviceFee / 100) +
                        (computeChargesDetails?.salary / 100)
                      ).toFixed(2))}</h4>

                    </ul>
                  </div>
                  )}
                  <h4>Total Charges</h4>
                  <ul>
                    <li>
                      <i className="feather-check" />
                      Total Booking and Processing Fee: $
                      {(
                        computeChargesDetails?.bookingAndProcessingFee * formValues.noOfCandidates / 100
                      ).toFixed(2)}
                    </li>
                    <li>
                      <i className="feather-check" />
                      Total Service Fee: $
                      {(computeChargesDetails?.serviceFee * formValues.noOfCandidates / 100).toFixed(2)}
                    </li>
                    <li>
                      <i className="feather-check" />
                      Total Salary: ${(computeChargesDetails?.salary * formValues.noOfCandidates / 100).toFixed(2)}
                    </li>
                  </ul>
                </div>
              {console.log("userInfo?.paymentMethod",userInfo?.paymentMethod)}

              {
                paymentMethodCheckLoader ? 
                <TableLoader /> 
                : 
                <>
                {(userInfo?.paymentMethod === undefined || userInfo?.paymentMethod === 'stripe' || userInfo?.paymentMethod?.replace(/^"(.*)"$/, '$1') === 'stripe') && (<div className="p-table-btn">
                <CardCheckoutForm
                  // totalCharges={(
                  //   computeChargesDetails?.totalCharges / 100
                  // ).toFixed(2)}
                  totalCharges={computeChargesDetails?.totalCharges} //must required to pass currency in cent to backend 
                  setShowPaymentScreen={setShowPaymentScreen}
                  postJobFunction={postJobFunction}
                  postJobReqParam={postJobReqParam}
                  handleHideJobType={handleHideJobType}
                  // clientSecret={clientSecret}
                  // response={achDetails}
                />
              </div>
              )}
              {(userInfo?.paymentMethod === 'other' || userInfo?.paymentMethod?.replace(/^"(.*)"$/, '$1') === 'other')  && (
                <div className="p-table-btn">
                  <WithoutStripeMethod
                  setShowPaymentScreen={setShowPaymentScreen}
                  handleHideJobType={handleHideJobType}
                  postJobFunction={postJobFunction}
                  postJobReqParam={postJobReqParam}
                />
                </div>
              )}
                </>
              }
            </div>
          </div>
        </div>
        <div className="col-md-2 p-table-highlight"></div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
    </Elements>
    // )
  );
};

export default CardPaymentScreen;
