import React from "react";
import { useSelector } from "react-redux";

function SectionShareProfile({ jobUrl, jobTitle }) {
  const { role_type } = useSelector((state) => state.menu);

  let shareUrl;
  let shareText;
  if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
    shareUrl = encodeURIComponent(window.location.href);
    shareText = encodeURIComponent("Check out this job profile!");
  } else {
    shareUrl = encodeURIComponent(jobUrl);
    shareText = encodeURIComponent(`Check out this job: ${jobTitle}`);
  }

  return (
    <>
      <h4 className="twm-s-title">Share on Social Media</h4>
      <div className="twm-social-tags">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          className="fb-clr"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          href={`https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`}
          className="tw-clr"
          target="_blank"
          rel="noopener noreferrer"
          style={{backgroundColor : "black"}}
        >
          X
        </a>
        <a
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${shareText}`}
          className="link-clr"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>
        <a
          href={`https://api.whatsapp.com/send?text=${shareText}%20${shareUrl}`}
          className="whats-clr"
          target="_blank"
          rel="noopener noreferrer"
        >
          WhatsApp
        </a>
        <a
          href={`http://pinterest.com/pin/create/button/?url=${shareUrl}&description=${shareText}`}
          className="pinte-clr"
          target="_blank"
          rel="noopener noreferrer"
        >
          Pinterest
        </a>
      </div>
    </>
  );
}

export default SectionShareProfile;
