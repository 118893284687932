import React, { useEffect, useState, useRef } from "react";
import BASE_URL from "../../../../../../config";
import { useSelector } from "react-redux";
import Toast from "../../../../../common/toast";
import axios from "axios";
import SessionExpired from "../../../../../common/sessionExpired";
import Spinner from "../../../../../common/spinner";
import apiCall from "../../../../../../apicall";
 
const DocumentViewUpdatePopup = ({ viewDocId, documentType, getDocuments, zoomLevel }) => {
  const fileInputRef = useRef(null);
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [approveDocLoader, setApproveDocLoader] = useState(false);
  const [documentImageSrc, setDocumentImageSrc] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [documentUploadType, setDocumentUploadType] = useState("")
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: false,
  });
 
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    // Determine the file type (image or PDF) and display accordingly
    if (file) {
      const reader = new FileReader();
      console.log("render=====",file.type)
      reader.onloadend = () => {
        if (file?.type === "application/pdf" || file?.type === "text/xml" ) {
          setDocumentUploadType("pdf");
          if(file?.type === "application/pdf"){
            setDocumentImageSrc(reader.result);
          }else{
            setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
          }
        } else {
          setDocumentUploadType("image");
          setDocumentImageSrc(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };
 
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };
 
  const handleUpload = async () => {
    if (!selectedFile) {
      setErrSuccessMsg({
        message: "Please upload a file before proceeding.",
        status: false,
      });
      return;
    }
 
    const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowedFileTypes.includes(selectedFile.type)) {
      setErrSuccessMsg({
        message: "Only PDF or image files are allowed.",
        status: false,
      });
      return;
    }
    // Check file size (1 MB = 1,000,000 bytes)
    if (selectedFile.size > 50000000) {
      setErrSuccessMsg({
        message: "File size must be less than 50 MB.",
        status: false,
      });
      return;
    }
 
    console.log("selectedFile-----", selectedFile);
 
    try {
      setApproveDocLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("documentType", viewDocId?.documentType);
      formData.append("fileType", selectedFile.type === "application/pdf" ? "ePdf" : "ePng");
 
      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
        },
      };
 
      const uploadResponse = await axios.post(
        `${BASE_URL}documents`,
        formData,
        config
      );
      if (uploadResponse?.status === 200) {
        setApproveDocLoader(false);
        setErrSuccessMsg({
          message: "File uploaded successfully!",
          status: true,
        });
 
        // Reset selectedFile and documentImageSrc
        setSelectedFile(null);
        // setDocumentImageSrc("");
        getDocuments(); //reset the doc list after upload
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else if (uploadResponse?.response?.status === 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
        setApproveDocLoader(false);
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(uploadResponse?.response?.data?.message)
        setApproveDocLoader(false);
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
      setApproveDocLoader(false);
    }
  };
 
  const handleViewDocCertificate = async () => {
    setIsLoading(true);
    // let data = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
 
    let url;
 
    // if (TOKEN) {
    //   data.headers["Authorization"] = "Bearer " + TOKEN;
    // }
 
    url = `documents/${viewDocId?.id}/download`;
 
    try {
      setIsLoading(true);
      setDocumentImageSrc("");
      // const viewDocCertificateResponse = await fetch(url, data);
      const viewDocCertificateResponse = await apiCall(url, "GET", null, TOKEN, false, true);
      if (viewDocCertificateResponse?.status === 200) {
        setIsLoading(false);
        console.log(
          "viewDocCertificateResponse----",
          viewDocCertificateResponse
        );
        const blobResponse = await viewDocCertificateResponse.data;
        const reader = new FileReader();

        const fileSignature = await blobResponse.slice(0, 4).arrayBuffer();
        const bytes = new Uint8Array(fileSignature);

        // Detect file type using magic bytes
        const detectFileType = (bytes) => {
          if (bytes[0] === 0x25 && bytes[1] === 0x50) return "application/pdf";   // PDF: "%PDF"
          if (bytes[0] === 0x89 && bytes[1] === 0x50) return "image/png";         // PNG: "\x89PNG"
          if (bytes[0] === 0xFF && bytes[1] === 0xD8) return "image/jpeg";        // JPEG: "\xFF\xD8"
          return blobResponse.type;  // Fallback to blob type
        };

        const fileType = detectFileType(bytes);
        // Handle PDF or Image display
        reader.onloadend = () => {
          if (fileType === "application/pdf") {
            setDocumentUploadType("pdf");
            if(reader?.result?.includes("text/xml")){
              setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
            }else{
              setDocumentImageSrc(reader.result);
            }
          }else if(fileType === "image/png" || fileType === "image/jpeg"){
            setDocumentUploadType("image");
            setDocumentImageSrc(reader.result);
          }else{
            setDocumentUploadType("pdf");
            setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
          }
        };

        reader.readAsDataURL(blobResponse);  // Convert to base64
        // reader.readAsDataURL(blobResponse);

        // if (blobResponse?.type === "application/pdf" || blobResponse?.type === "text/xml") {
        //   reader.onloadend = () => {
        //     console.log("render.result====",reader.result)
        //   setDocumentUploadType("pdf");
        //   if(blobResponse?.type === "application/pdf"){
        //     setDocumentImageSrc(reader.result);
        //   }else{
        //     setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
        //   }
        //   }
        // } else {
        //   reader.onloadend = () => {
        //   setDocumentUploadType("image");
        //   setDocumentImageSrc(reader.result);
        //   };
        // }
 
      
      } else if (viewDocCertificateResponse?.response?.status === 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        setIsLoading(false);
        // displayErrMsg("Something went wrong!");
        // displayErrMsg(viewDocCertificateResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };
 
  useEffect(() => {
    if (documentType === "document" && viewDocId?.id) {
      handleViewDocCertificate();
    } else {
 
      //reset the state when there is no doc id
      setSelectedFile(null);
      setDocumentImageSrc("");
    }
  }, [documentType, viewDocId]);
 
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : documentImageSrc ? (
        (documentUploadType === "pdf") ? (
          <iframe
            src={documentImageSrc}
            title="Document"
            style={{ width: "100%", height: "500px" }}
          />
        ) : (
          <img src={documentImageSrc} alt="Document" style={{ maxWidth: "100%", maxHeight: '600px', transform: `scale(${zoomLevel})`, transformOrigin: "center" }} />
        )
      ) : (
        ""
      )}
      <div style={{ marginTop: "20px" }}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          accept="image/*,application/pdf"
        />
        <button
          className="site-button mt-3"
          onClick={handleUpload}
          disabled={!selectedFile || approveDocLoader}
        >
          {approveDocLoader ? "Uploading..." : "Upload"}
        </button>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message !== "" && <Toast message={errSuccessMesg} />}
    </>
  );
};
 
export default DocumentViewUpdatePopup;