import React, { useEffect, useState} from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SectionPagination from "../../sections/common/section-pagination";
import GetProfileImage from "../../../../common/getProfileImage";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import apiCall from "../../../../../apicall";
import Toast from "../../../../common/toast";

const EmpJobList = ({
  empDetailId,
  handleJobsListRes,
  isJobListRefreshed
  // empJobsList,
  // empJobsLoader,
  // rowUsersPerPage,
  // handleRecordsJobsPerPageChange,
  // paginationJobsData,
  // setCurrentPage,
  // currentPage,
  // tableFilter,
  // setTableFilter,
}) => {
  const { role_type , TOKEN } = useSelector((state) => state.menu);
  const [empJobsInfo, setEmpJobsInfo] = useState({});
  const [jobsLoader, setJobsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [rowJobsPerPage, setJobsRowPerPage] = useState(10);
  const [paginationJobsData, setPaginationJobsData] = useState({
    totalElements: 0,
    totalPages: 0
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    status: "",
    searchKey: ""
  });

  // console.log("paginationJobsData", paginationJobsData, empJobsList);
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationJobsData?.totalElements,
    showRange: false,
    showingUpto: "",
  };
  const navigate = useNavigate();

  const StarRating = ({ rating }) => {
    const stars = [];
    console.log("rating----------", rating);
    // Iterate over 5 stars
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        // Full star if rating is greater than or equal to current index + 1
        stars.push(
          <i key={i} className="fas fa-star" style={{ color: "#128c5e" }} />
        );
      } else if (rating >= i + 0.5) {
        // Half star if rating is greater than or equal to current index + 0.5
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: "#128c5e" }}
          />
        );
      } else {
        // Empty star otherwise
        stars.push(
          <i key={i} className="fas fa-star" style={{ color: "lightgrey" }} />
        );
      }
    }

    return <div>{stars}</div>;
  };

  function getTimeDifference(postTime) {
    const currentTime = new Date();
    const postDate = new Date(postTime);
    const timeDifference = currentTime.getTime() - postDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours < 24) {
      return `${hours} hour ago`;
    } else if (days < 30) {
      return `${days} day ago`;
    } else if (months < 12) {
      return `${months} month ago`;
    } else {
      return `${years} year ago`;
    }
  }
  const getJobValue = (status, item) => {
    if (status === "eApplied") {
      return <h5 style={{ color: "orange" }}>PENDING</h5>; // Return an empty string or handle it as needed
    } else if(status === "eCancelled") {
      return <h5 style={{ fontWeight: "600" }}>Cancelled</h5>;
    }
     else if (status === "eAccepted") {
      return <h5 style={{ color: "green" }}>HIRED</h5>;
    } if (status === "eUnAvailable") {
      return <h5 style={{ color: "red" }}>Rejected</h5>;
     } else if (
      ((status === "eCancelled" || status === "eInProgress")) ||
      ((status === "eInProgress" || status === "eAccepted"))
    ) {
      return (
        <h5
          style={{
            fontWeight: "600",
            color:
              item?.clockInStatus == "TIME PASSED"
                ? "black"
                : item?.clockInStatus === "Upcoming"
                  ? "orange"
                  : item?.clockInStatus === "Clocked Out"
                    ? "red"
                    : item?.clockInStatus === "Clocked In"
                      ? "green"
                      : "black",
          }}
        >
          {item?.clockInStatus?.toUpperCase()}
        </h5>
      );
    } else {
      return <h5 style={{ fontWeight: "600" }}>{status.startsWith("e") ? status.substring(1) : status}</h5>;
    }
  };
  const navigateToJobDetails = (item) => {
    // dispatch(jobDetailsData(item))
    navigate(`/job-detail/${item.id}`, { state: item });
  };

  const displayJobTime = (startTime, endTime, isMultiDayJob, zone) => {
    const formattedStartTime = moment
      .utc(startTime, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("MM/DD/YYYY");
    const formattedEndTime = moment
      .utc(endTime, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("MM/DD/YYYY");

    const displayTime = isMultiDayJob
      ? `${formattedStartTime} - ${formattedEndTime}`
      : `${formattedStartTime} ${moment
          .utc(startTime, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("hh:mm A")} - ${moment
          .utc(endTime, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("hh:mm A")}`;

    return (
      <div>
        {displayTime} <b>{zone}</b>
      </div>
    );
  };

  const displayEmpLocationErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpJobsInfo({});
    setPaginationJobsData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const handleRecordsJobsPerPageChange = (event) => {
    setCurrentPage(1);
    setJobsRowPerPage(event.target.value);
  };

  const getJobsData = async () => {
    try {
      setJobsLoader(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
          && `openstaff/jobs/${empDetailId}/institutionsJobs?offset=0&sortBy=${
            tableFilter?.sortType
          }&sortDirection=${tableFilter?.sortDirection}&customerId=${empDetailId}&page=${
              currentPage - 1
            }&size=${rowJobsPerPage}&searchKey=${tableFilter?.searchKey}`
      }`;
      const empJobsResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empJobsResponse---", empJobsResponse);

      if (empJobsResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpJobsInfo(empJobsResponse.data.content);
        setPaginationJobsData({
          totalElements: empJobsResponse.data.totalElements,
          totalPages: empJobsResponse.data.totalPages,
        });
        handleJobsListRes(empJobsResponse.data.content, false)
      } else if (empJobsResponse?.data.content?.length == 0) {
        setEmpJobsInfo({});
        setPaginationJobsData({
          totalElements: 0,
          totalPages: 0,
        });
        handleJobsListRes({}, false)
      } else if (empJobsResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpLocationErrMsg("Your session has been expired!!");
        handleJobsListRes({}, false)
      } else {
        setSessionExpired(false);
        displayEmpLocationErrMsg("Something went wrong!");
        handleJobsListRes({}, false)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpLocationErrMsg("Something went wrong!");
      handleJobsListRes({}, false)
    }finally{
      setJobsLoader(false);
    }
  };

    useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")){
      getJobsData();
    }
  }, [currentPage, rowJobsPerPage, tableFilter, isJobListRefreshed]);

  return (
    <>
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowJobsPerPage}
        handleRecordsPerPageChange={handleRecordsJobsPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
      <div className="twm-candidates-list-wrap">
        <ul>
          {!jobsLoader ? (
            empJobsInfo?.length > 0 ? (
              empJobsInfo?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-media">
                      <div className="twm-media-pic">
                        <GetProfileImage
                          id={item?.institutionEntityId}
                          type={"jobList"}
                        />
                      </div>
                      <div className="twm-candidates-tag">
                        {/* <span>{item?.jobStatus || "-"}</span> */}
                        {getJobValue(item?.jobStatus, item)}
                      </div>
                    </div>
                    <div className="twm-mid-content"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateToJobDetails(item)}
                    >
                      <span className="twm-job-title">
                        <h4>
                          {item?.jobTitle}
                          <span style={{ fontSize: 14 }}>
                            / {getTimeDifference(item?.createdDate)}
                          </span>
                        </h4>
                        <h5>
                          {item?.jobPosition}
                          <span className="twm-job-post-duration"></span>
                        </h5>
                      </span>
                      <div className="row col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-sm-6">
                          <>
                            <StarRating rating={item?.instituteAvgRating} />
                            <span style={{ fontSize: 12 }}>
                              {" " + (item?.instituteReview ?? 0) + " "}
                              Reviews
                            </span>
                          </>
                          <p className="twm-candidate-address">
                            <b>{item?.institutionName}</b>
                          </p>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="twm-candidate-address">
                            {item?.address}
                          </div>
                          <span className="twm-job-address">
                            {displayJobTime(
                              item?.startTime,
                              item?.endTime,
                              item?.multipleDayJob,
                              item?.timeZoneId
                            )}
                          </span>
                          <p className="twm-candidate-address">
                            <b>Salary: </b>$ {(item?.salary / 100).toFixed(2)}<span>/ hr</span>
                          </p>
                          <span
                            className="twm-jobs-browse site-text-primary job-details"
                            onClick={() => navigateToJobDetails(item)}
                          >
                            View Details
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No results found."
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        <SectionPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={paginationJobsData?.totalPages}
        />
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default EmpJobList;
