import React, { useState } from "react";
import CertificateList from "./certificateList";
import DocumentViewUpdatePopup from "./documentViewUpdatePopup";
import BackgroundForm from "./backgroundForm";
import BackgroundFormUpload from "./backgroundFormUpload";


const ViewDocumentPopup = ({
  viewDocId,
  documentType,
  certificateList,
  backgroundScreenFormData,
  getCertificates,
  getDocuments,
  getBackgroundForms,
}) => {
  
const isEnabledBackgroundForm = process.env.REACT_APP_ENABLED_BACKGROUNDFORM === "true";
console.log("bbbbbbbbbb", isEnabledBackgroundForm, process.env.REACT_APP_ENABLED_BACKGROUNDFORM)

const [zoomLevel, setZoomLevel] = useState(1); // Default zoom level

const handleZoomIn = () => {
  setZoomLevel((prev) => Math.min(prev + 0.2, 3)); // Maximum zoom 3x
};

const handleZoomOut = () => {
  setZoomLevel((prev) => Math.max(prev - 0.2, 0.5)); // Minimum zoom 0.5x
};

const handleResetZoom = () => {
  setZoomLevel(1);
};
  return (
    <div
      className="modal fade twm-sign-up"
      id={`${
        (documentType === "document" || documentType === "background")
          ? "view_app_doc"
          : documentType === "certificate"
          ? "view_app_certificate"
          : ""
      }`}
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header mt-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleResetZoom}
            />
            {documentType === "document" || !isEnabledBackgroundForm && (
              <div className="d-flex justify-content-center">
                <span style={{fontSize : "20px", cursor: "pointer" }} onClick={handleZoomIn}><i className="fas fa-search-plus"></i></span>
                <span style={{fontSize : "20px", cursor: "pointer", marginLeft: "10px"}} onClick={handleZoomOut}><i className="fas fa-search-minus"></i></span>
              </div>
            )}
          </div>

          <div className="modal-body" style={{ textAlign: "center", overflow: "auto", maxHeight: "600px" }}>
            {documentType === "document" ? (
              <DocumentViewUpdatePopup
                viewDocId={viewDocId}
                documentType={documentType}
                getDocuments={getDocuments}
                zoomLevel={zoomLevel}
              />
            ) : documentType === "certificate" ? (
              <CertificateList
                certificateList={certificateList}
                getCertificates={getCertificates}
              />
            ) : documentType === "background" ? (
              isEnabledBackgroundForm ?
              <BackgroundForm
                getBackgroundForms={getBackgroundForms}
                backgroundScreenFormData={backgroundScreenFormData}
              />
              :
              <BackgroundFormUpload 
               getBackgroundForms={getBackgroundForms}
               backgroundScreenFormData={backgroundScreenFormData}
               zoomLevel={zoomLevel}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewDocumentPopup;
