import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { useState, useCallback, useEffect } from "react";
import { publicUrlFor } from "../../../../../globals/constants";
import TableLoader from "../../../../common/tableLoader";
import moment from "moment";
// import { GOOGLE_API } from "../../../../../config";

const mapOptions = {
  zoom: 3,
  scrollwheel: true,
  center: {
    lat: 40.8,
    lng: -73.7,
  },
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  panControl: false,
  navigationControl: false,
  streetViewControl: false,
  gestureHandling: "cooperative",
  mapTypeId: "roadmap",
  styles: [
    {
      featureType: "administrative.locality",
      elementType: "all",
      stylers: [
        {
          hue: "#2c2e33",
        },
        {
          saturation: 7,
        },
        {
          lightness: 19,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "-3",
        },
      ],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#1967d2",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          hue: "#ffffff",
        },
        {
          saturation: -100,
        },
        {
          lightness: 100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          hue: "#ffffff",
        },
        {
          saturation: -100,
        },
        {
          lightness: 100,
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#1967d2",
        },
        {
          saturation: "0",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          hue: "#ff6f00",
        },
        {
          saturation: "100",
        },
        {
          lightness: 31,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#1967d2",
        },
        {
          saturation: "0",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [
        {
          hue: "#008eff",
        },
        {
          saturation: -93,
        },
        {
          lightness: 31,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry.stroke",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#f3dbc8",
        },
        {
          saturation: "0",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels",
      stylers: [
        {
          hue: "#bbc0c4",
        },
        {
          saturation: -93,
        },
        {
          lightness: -2,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          hue: "#e9ebed",
        },
        {
          saturation: -90,
        },
        {
          lightness: -8,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          hue: "#e9ebed",
        },
        {
          saturation: 10,
        },
        {
          lightness: 69,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          hue: "#e9ebed",
        },
        {
          saturation: -78,
        },
        {
          lightness: 67,
        },
        {
          visibility: "simplified",
        },
      ],
    },
  ],
};
function SectionJobsGridMap({ isLoading, jobListData, height, isClockinClockout }) {
  const [mapContainer, setMapContainer] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const [markerClusterer, setMarkerClusterer] = useState(null);

  const onLoad = useCallback((map) => {
    setMapInstance(map);
  }, []);

  const getClockinClockoutTimeFormat = (time) => {
    return time
      ? moment
          .utc(time, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("MM-DD-YYYY hh:mm A")
      : "-";
  };

  const addMarkers = useCallback(async (map, jobListData) => {
    const google = window.google;
    if (!google || !map || !google.maps) return;

    const validJobListData = jobListData?.length > 0 ? jobListData?.filter(
      (job) => job.location && job.location.latitude && job.location.longitude
    ): [];

    console.log("validajoblistdate",validJobListData)

    if (validJobListData.length === 0) {
      return;
    }

    const newMarkers = validJobListData.map((job, index) => {
      const { address, location } = job;
      const { latitude, longitude } = location;

      const infoWindow = new google.maps.InfoWindow();
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(latitude, longitude),
        animation: google.maps.Animation.DROP,
      });

      marker.addListener("mouseover", () => {
        marker.setAnimation(google.maps.Animation.BOUNCE);
      });

      marker.addListener("mouseout", () => {
        marker.setAnimation(null);
      });
     
      marker.addListener("click", () => {
        infoWindow.close();
        infoWindow.setPosition({ lat: latitude, lng: longitude });
        if (isClockinClockout) {
          infoWindow.setContent(
            `<div class="map-box">
              <div class="listing-item-content">
                <p>
                  <strong>Serial:</strong> ${index + 1}
                </p>
                <p>
                   <strong>Time:</strong> ${getClockinClockoutTimeFormat(job?.checkinTime || job?.checkoutTime)}
                </p>
              </div>
            </div>`
          );
        } else {
          infoWindow.setContent(
            `<div class="map-box">
              <div class="listing-item-content">
                <span>${address}</span>
              </div>
            </div>`
          );
        }

        infoWindow.open({ map });
      });

      return marker;
    });

    if (markerClusterer) {
      markerClusterer.clearMarkers();
      markerClusterer.addMarkers(newMarkers);
    } else {
      const clusterer = new MarkerClusterer({
        map,
        markers: newMarkers,
        algorithm: new SuperClusterAlgorithm({ radius: 200 }),
      });
      setMarkerClusterer(clusterer);
    }

    if (newMarkers.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      newMarkers.forEach((marker) => {
        const position = marker.getPosition();
        if (position) {
          bounds.extend(position);
        }
      });
      map.fitBounds(bounds);
    }
  }, [markerClusterer]);

  useEffect(() => {
    if (!isLoading && jobListData && mapInstance) {
      addMarkers(mapInstance, jobListData);
    }
  }, [isLoading, jobListData, mapInstance, addMarkers]);

  return (
    <>
      {isLoading ? (
        <TableLoader />
      ) : (
        <GoogleMapsProvider
          googleMapsAPIKey={process.env.REACT_APP_GOOGLE_API}
          mapOptions={mapOptions}
          mapContainer={mapContainer}
          onLoadMap={onLoad}
        >
          <div
            ref={(node) => setMapContainer(node)}
            style={{ height: `${height}vh` }}
          ></div>
        </GoogleMapsProvider>
      )}
    </>
  );
}

export default SectionJobsGridMap;