import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useLoadScript,
  InfoWindow
} from "@react-google-maps/api";
import EditClockInClockOutPopup from "./edit-clockin-clockout-popup";
import { useSelector } from "react-redux";
import DeleteClockinOutConfirmation from "./delete-confirmation-clockinout";
import SectionJobsGridMap from "../../sections/jobs/section-jobs-grid-map";
// import { GOOGLE_API } from "../../../../../config";
// import ProfileIcon from "../../../../common/marker/userImage.png";
function ClockInClockOutAdmin({ clockinClockoutList, jobDetailsInfo, getJobDetailsData, selectedClockinClockout, handleDeleteClick, handleEditClick, handleUpdateClockinClockout }) {

  const { role_type } = useSelector((state)=>state.menu)
  let markerPositions = [];
  // const[selectedClockinClockout, setSelectedClockinClockout] = useState([])
  const [mapCenter, setMapCenter] = useState({
    lat: process.env.REACT_APP_DEFAULT_LATITUDE,
    lng: process.env.REACT_APP_DEFAULT_LONGITUDE,
  });

  console.log("process.env.REACT_APP_DEFAULT_LATITUDE==",process.env.REACT_APP_DEFAULT_LATITUDE,process.env.REACT_APP_DEFAULT_LONGITUDE)
  
  // const [activeMarker, setActiveMarker] = useState(null);
  // const [markerData, setMarkerData] = useState(null);
  // const mapContainerStyle = {
  //   width: "100%",
  //   height: 310,
  //   borderRadius: "8px",
  // };
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
  });

  var groupedData = [];
  var groupedByDate = {};

  // function calculateWorkHours(checkinTime, checkoutTime) {
  //   var startTime = new Date(checkinTime);
  //   var endTime = new Date(checkoutTime);
  //   var difference = endTime.getTime() - startTime.getTime();
  //   var diffrece_hours = difference / (1000 * 60 * 60);
  //   var hours = Math.floor(diffrece_hours);
  //   var minutes = Math.round((diffrece_hours - hours) * 60);
  //   if (hours >= 0 && minutes >= 0) {
  //     return hours + " hours " + minutes + " minutes";
  //   } else {
  //     return "-";
  //   }
  // }

  function truncateToTwoDecimals(num) {
    const numStr = num.toString();
    const decimalIndex = numStr.indexOf('.');
    if (decimalIndex === -1) return numStr; // No decimals, return as is

    return numStr.substring(0, decimalIndex + 3); // Truncate to two decimal places
  }

  function calculateWorkHours(checkinTime, checkoutTime) {
    var startTime = new Date(checkinTime);
    var endTime = new Date(checkoutTime);

    var totalHours = 0;
    var totalMinutes = 0;

    // startTime.setSeconds(0, 0);
    // endTime.setSeconds(0, 0);

    var difference = endTime.getTime() - startTime.getTime();
    var diffrece_hours = difference / (1000 * 60 * 60);
    var hours = diffrece_hours;
    var minutes = (diffrece_hours - hours) * 60;

    totalHours += hours * 60;
    totalMinutes = minutes + totalHours;
    let fnlHrs = (totalMinutes / 60)?.toFixed(2);
    let fnl2 = truncateToTwoDecimals(fnlHrs);
    console.log("fnl2====",fnlHrs)
    return fnl2; // Convert milliseconds to hours
  }

  // clockinClockoutList.forEach((item) => {
  //   var currentData = item;
  //   var lastGroup = groupedData[groupedData.length - 1];
  //   if (currentData.checkinTime && !currentData.checkoutTime) {
  //     groupedData.push([currentData]);
  //   } else if (!currentData.checkinTime && currentData.checkoutTime) {
  //     if (lastGroup) {
  //       lastGroup.push(currentData);
  //     }
  //   }

  //   function groupByDate(data) {
  //     const groupedData = {};
  //     data.forEach((array) => {
  //       array.forEach((item) => {
  //         const date = item.createdDate.split("T")[0]; // Extracting the date part
  //         if (!groupedData[date]) {
  //           groupedData[date] = [];
  //         }
  //         groupedData[date].push(item);
  //       });
  //     });
  //     return groupedData;
  //   }

  //   groupedByDate = groupByDate(groupedData);
  // });

  clockinClockoutList.forEach((item) => {
    var currentData = item;
    var lastGroup = groupedData[groupedData.length - 1];

    if (currentData.checkinTime && !currentData.checkoutTime) {
        groupedData.push([currentData]);
    } else if (!currentData.checkinTime && currentData.checkoutTime) {
        if (lastGroup) {
            lastGroup.push(currentData);
        }
    }
    });

    function groupByCheckinOrCheckout(data) {
        const groupedData = {};

        data.forEach((array) => {
            array.forEach((item) => {
                // Extract the date from checkinTime if it exists, otherwise from checkoutTime
                const date = item.checkinTime
                    ? item.checkinTime.split(" ")[0] // Extract date from checkinTime
                    : item.checkoutTime
                    ? item.checkoutTime.split(" ")[0] // Extract date from checkoutTime
                    : null;

                if (date) {
                    if (!groupedData[date]) {
                        groupedData[date] = [];
                    }
                    groupedData[date].push(item);
                }
            });
        });

        return groupedData;
    }

    // Call the function
     groupedByDate = groupByCheckinOrCheckout(groupedData);

  console.log("groupedByDate", groupedByDate);

  const formated_data = {};

  for (const [date, records] of Object.entries(groupedByDate)) {
    const groupedRecords = [];
    let tempRecord = {};

    for (const record of records) {
      if (record.checkinTime) {
        if (tempRecord.checkinTime) {
          groupedRecords.push({ ...tempRecord });
          tempRecord = {};
        }
        tempRecord.checkinData = { ...record };
      }

      if (record.checkoutTime) {
        tempRecord.checkoutData = { ...record };
        groupedRecords.push({ ...tempRecord });
        tempRecord = {};
      }
    }

    if (Object.keys(tempRecord).length > 0) {
      groupedRecords.push({ ...tempRecord });
    }
    formated_data[date] = groupedRecords;
  }

//   const handleEditClick = (record) =>{
//       console.log("recordd=======",record)
//       setSelectedClockinClockout(record)
//   }

//   const handleDeleteClick = (record) =>{
//     console.log("recordd=======",record)
//     setSelectedClockinClockout(record)
// }

  // useEffect(() => {
  //   setMapCenter({
  //     lat: clockinClockoutList[0]?.location?.longitude,
  //     lng: clockinClockoutList[0]?.location?.longitude?.latitude,
  //   });
  // }, []);
  useEffect(() => {
    setMapCenter({
      lat: parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE),
      lng: parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE),
    });
  }, [clockinClockoutList]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  console.log(
    "Object.keys(formated_data).length",
    Object.keys(formated_data)?.length
  );

  return (
    <div className="tw-faq-section mt-2">
      <div className="accordion tw-faq" id="sf-faq-accordion-2">
        {Object.keys(formated_data).length === 0 ? (
          <div>No records found!</div>
        ) : (
          Object.entries(formated_data).map(([date, records], index) => {
            let totalWorkedHours = 0;
            let totalWorkedMinutes = 0;
            records.forEach((record) => {
              const workHours = calculateWorkHours(
                record?.checkinData?.checkinTime,
                record?.checkoutData?.checkoutTime
              );
              // console.log("workHours---------",workHours)
              // if (workHours !== "-" && workHours !== "NaN") {
                // const [hours, minutes] = workHours.split(".");
                // totalWorkedHours += parseInt(hours, 10);
                // totalWorkedMinutes += parseInt(minutes, 10) || 0;
                // totalWorkedHours += Number(workHours) || 0;
              // }
            });
            // totalWorkedHours += Math.floor(totalWorkedMinutes / 60);
            // totalWorkedMinutes %= 60;

            return (
              <div className="accordion-item" key={index}>
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#FAQ${index + 1}-2`}
                  aria-expanded="false"
                >
                  {/* Accordian title */}
                  Date: {date} 
                  {/* Logged Hours: {totalWorkedHours} hours{" "} */}
                  {/* {totalWorkedMinutes} minutes */}
                </button>
                <div
                  id={`FAQ${index + 1}-2`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#sf-faq-accordion-2"
                >
                  <div className="accordion-body">
                    {/* Accordian Content */}
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Check In</th>
                          <th>Check Out</th>
                          <th>Work Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {records.map((record, idx) => (
                          <tr key={idx}>
                            <td>
                              {record?.checkinData?.checkinTime
                                ? moment.utc(
                                    record.checkinData.checkinTime,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).local().format("hh:mm A")
                                : " -"}
                            </td>
                            <td>
                              {record?.checkoutData?.checkoutTime
                                ? moment.utc(
                                    record.checkoutData.checkoutTime,
                                    "YYYY-MM-DD HH:mm:ss"
                                  ).local().format("hh:mm A")
                                : " -"}
                            </td>
                            
                            <td>
                              {record?.checkinData?.checkinTime && record?.checkoutData?.checkoutTime ?
                              calculateWorkHours(
                                record?.checkinData?.checkinTime,
                                record?.checkoutData?.checkoutTime
                              ) : 0}
                            </td>
                            {
                              (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && jobDetailsInfo?.jobStatus !== "eCompleted"  && (
                                <td>
                                  <a
                                    className="edit-link m-b10 mr-5"
                                    data-bs-toggle="modal"
                                    href="#edit_popup"
                                    role="button"
                                    onClick={() => handleEditClick(record)}
                                  >
                                    <i className="fas fa-edit edit-icon"></i>
                                  </a>
                                  <a
                                    className="edit-link m-b10"
                                    data-bs-toggle="modal"
                                    href="#delete_clockinout_popup"
                                    role="button"
                                    onClick={() => handleDeleteClick(record)}
                                  >
                                    <i className="fas fa-trash trash-icon" style={{color:'red'}}></i>
                                  </a>
                                </td>
                              )
                            }
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            );
          })
        )}
        {/* { lat: clockinClockoutList[0]?.location?.latitude, lng: clockinClockoutList[0]?.location?.longitude } */}
      </div>
      <SectionJobsGridMap
        isLoading={false}
        jobListData={clockinClockoutList}
        height={50}
        isClockinClockout={true}
     />
      {/* <GoogleMap
        center={mapCenter}
        zoom={8}
        apiKey={process.env.REACT_APP_GOOGLE_API}
        mapContainerStyle={mapContainerStyle}
      >
        <MarkerClusterer>
          {(clusterer) =>
            Object.entries(formated_data).map(([date, records], index) =>
              records.map((record, idx) => {
                // Push marker positions into the array
               {console.log("formated_data==========",record)}
                if (record?.checkinData?.location?.latitude) {
                  markerPositions.push({
                    lat: parseFloat(record.checkinData.location.latitude),
                    lng: parseFloat(record.checkinData.location.longitude),
                  });
                  return (
                    <Marker
                      key={`${index}-${idx}`}
                      position={{
                        lat: parseFloat(record.checkinData.location.latitude),
                        lng: parseFloat(record.checkinData.location.longitude),
                      }}
                      onClick={() => {
                        setActiveMarker(`${index}-${idx}`);
                        setMarkerData({
                          serial: idx + 1,
                          time: record?.checkinData?.checkinTime
                            ? moment(
                                record.checkinData.checkinTime,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("hh:mm A")
                            : record?.checkoutData?.checkoutTime
                            ? moment(
                                record.checkoutData.checkoutTime,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("hh:mm A")
                            : " -",
                        });
                      }}
                    >
                      {activeMarker === `${index}-${idx}` && (
                        <InfoWindow
                          onCloseClick={() => {
                            setActiveMarker(null);
                            setMarkerData(null);
                          }}
                        >
                          <div>
                            <p>
                              <strong>Serial:</strong> {markerData.serial}
                            </p>
                            <p>
                              <strong>Time:</strong> {markerData.time}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  );
                } else if (record?.checkoutData?.location?.latitude) {
                  markerPositions.push({
                    lat: parseFloat(record.checkoutData.location.latitude),
                    lng: parseFloat(record.checkoutData.location.longitude),
                  });
                  return (
                    <Marker
                      key={`${index}-${idx}`}
                      position={{
                        lat: parseFloat(record.checkoutData.location.latitude),
                        lng: parseFloat(record.checkoutData.location.longitude),
                      }}
                      clusterer={clusterer}
                      onClick={() => {
                        setActiveMarker(`${index}-${idx}`);
                        setMarkerData({
                          serial: idx + 1,
                          time: record?.checkinData?.checkinTime
                            ? moment(
                                record.checkinData.checkinTime,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("hh:mm A")
                            : record?.checkoutData?.checkoutTime
                            ? moment(
                                record.checkoutData.checkoutTime,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("hh:mm A")
                            : " -",
                        });
                      }}
                    >
                      {activeMarker === `${index}-${idx}` && (
                        <InfoWindow
                          onCloseClick={() => {
                            setActiveMarker(null);
                            setMarkerData(null);
                          }}
                        >
                          <div>
                            <p>
                              <strong>Serial:</strong> {markerData.serial}
                            </p>
                            <p>
                              <strong>Time:</strong> {markerData.time}
                            </p>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  );
                } else {
                  return null; // Return null for cases where marker position is not available
                }
              })
            )
          }
        </MarkerClusterer>
      </GoogleMap> */}
      <EditClockInClockOutPopup selectedClockinClockout={selectedClockinClockout} handleUpdateClockinClockout={handleUpdateClockinClockout} getJobDetailsData={getJobDetailsData} />
      <DeleteClockinOutConfirmation selectedClockinClockout={selectedClockinClockout} getJobDetailsData={getJobDetailsData} />
    </div>
  );
}

export default ClockInClockOutAdmin;
