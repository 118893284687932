import React, { useState } from "react";
import TableLoader from "../../../../../common/tableLoader";
import SectionPagination from "../../../sections/common/section-pagination";
import GetProfileImage from "../../../../../common/getProfileImage";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import ConfirmationPopUp from "./confirmationPopup";

function SectionUSersList({
  isLoading,
  userListData,
  currentPage,
  setCurrentPage,
  totalPages,
}) {
  const { role_type } = useSelector((state) => state.menu);
  const [userId, setUserId] = useState('')

  const roleTypeMapping = {
    ROLE_USER: "ROLE_JOB_SEEKER",
    ROLE_INSTITUTION_ADMIN: "ROLE_JOB_POSTER",
    ROLE_SEEKER_USER_ADMIN: "ROLE_JOB_SEEKER_ADMIN",
    ROLE_INSTITUTION_POSTER_ADMIN: "ROLE_JOB_POSTER_ADMIN",
    ROLE_OPENSTAFF_FINANCIAL_ADMIN: "ROLE_FINANCIAL_ADMIN",
    ROLE_OPENSTAFF_ADMIN: "ROLE_SUPER_ADMIN",
  };

  const openDeletionConfirmation = (id) =>{
    console.log("openDeletionConfirmation---",id)
    setUserId(id)
  }
  return (
    <>
      <div>
        <div className="twm-jobs-list-wrap">
          <ul>
            {!isLoading ? (
              userListData?.length > 0 ? (
                userListData?.map((item) => (
                  <li key={item.id}>
                    <div className="twm-jobs-list-style1 mb-5">
                      <div className="twm-media">

                        <GetProfileImage id={item?.id} type={'userAvatar'} />
                      </div>
                      <div className="twm-mid-content">
                        <NavLink to="#" className="twm-job-title">
                          <h4>
                            {`${item?.firstName} ${item?.lastName}`}
                            {/* <span className="twm-job-post-duration">
                              / {item?.userName}
                            </span> */}
                          </h4>
                        </NavLink>
                        <span className="twm-job-address">{item?.email}</span>
                        <p className="twm-job-address">
                          Created On:{" "}
                          {moment(item?.roleEntities[0]?.createdDate).format(
                            "MM/DD/YYYY"
                          )}
                        </p>
                        {item?.institutionName != "" ? (
                          <span className="twm-job-address">
                            Institution Name: {item.institutionName}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="twm-right-content">
                        {role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                          <div className="twm-jobs-category green">
                            <span
                              className={
                                item?.accountStatus === "ACTIVE"
                                  ? "twm-bg-green"
                                  : "twm-bg-brown"
                              }
                            >
                              {item?.accountStatus}
                            </span>
                          </div>
                        )}

                        <div className="twm-jobs-amount">
                        {roleTypeMapping[item?.roleEntities[0]?.roleType] || item?.roleEntities[0]?.roleType}
                        </div>
                        <a
                          className="twm-jobs-browse site-text-primary job-details"
                          data-bs-toggle="modal" href="#delete_confirmation_popup" role="button"
                          onClick={() => openDeletionConfirmation(item?.id)}
                        >
                          Delete User
                        </a>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                "No results found."
              )
            ) : (
              <TableLoader />
            )}
          </ul>
        </div>
        <ConfirmationPopUp userId={userId} />
        <SectionPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
}

export default SectionUSersList;
