import React, { useState, useEffect } from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SectionPagination from "../../sections/common/section-pagination";
// import GetProfileImage from "../../../../common/getProfileImage";
// import { publicUser } from "../../../../../globals/route-names";
// import { NavLink } from "react-router-dom";
// import moment from "moment";
import PostJobPopup from "../../../../common/popups/popup-post-job";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import AddLocation from "./addLocation";

const EmpLocationList = ({
     empDetailId,
     empDetailsInfo,
     handleEmpLocationListRes,
     handleRefreshJobList
  // empLocationList,
  // empLocationLoader,
  // rowLocationPerPage,
  // handleRecordsLocationPerPageChange,
  // paginationLocationData,
  // locationCurrentPage,
  // setLocationCurrentPage,
  // tableFilter,
  // setTableFilter,
  // empDetailId,
  // empDetailsInfo
}) => {
  const { role_type , TOKEN } = useSelector((state) => state.menu);

  const [sessionExpired, setSessionExpired] = useState(false);
  const [empLocationInfo, setEmpLocationInfo] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [locationLoader, setLocationLoader] = useState(false);
  const [locationCurrentPage, setLocationCurrentPage] = useState(1);
  const [rowLocationPerPage, setLocationRowPerPage] = useState(10);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [paginationLocationData, setPaginationLocationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    status: "",
    searchKey: "",
  });

  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: false,
    total: paginationLocationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  // const StarRating = ({ rating }) => {
  //   const stars = [];
  //   for (let i = 0; i < 5; i++) {
  //     if (i < rating) {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "#128c5e" }}
  //         />
  //       );
  //     } else {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="feather feather-star"
  //           style={{ color: "lightgrey" }}
  //         />
  //       );
  //     }
  //   }
  //   return stars;
  // };

  const handleRecordsLocationPerPageChange = (event) => {
    setLocationCurrentPage(1);
    setLocationRowPerPage(event.target.value);
  };

  const displayEmpLocationErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpLocationInfo({});
    setPaginationLocationData({
      totalElements: 0,
      totalPages: 0,
    });
    setLocationLoader(false);
  };

  const handlePostJobClick = (itemId) => {
    setModalShow(true)
    setSelectedItemId(itemId);
  };

  const getEmpLocationData = async () => {
    try {
      setLocationLoader(true);
      const api = `${
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
          && `openstaff/institutions/${empDetailId}/locations?offset=0&sort=${
              tableFilter?.sortType
            },${tableFilter?.sortDirection}&page=${
              locationCurrentPage - 1
            }&size=${rowLocationPerPage}`
      }`;
      const empLocationResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empLocationResponse---", empLocationResponse);

      if (empLocationResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpLocationInfo(empLocationResponse.data.content);
        setPaginationLocationData({
          totalElements: empLocationResponse.data.totalElements,
          totalPages: empLocationResponse.data.totalPages,
        });
        handleEmpLocationListRes(empLocationResponse.data.content, false)
      } else if (empLocationResponse?.data.content?.length == 0) {
        setEmpLocationInfo({});
        setPaginationLocationData({
          totalElements: 0,
          totalPages: 0,
        });
        handleEmpLocationListRes({}, false)
      } else if (empLocationResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpLocationErrMsg("Your session has been expired!!");
        handleEmpLocationListRes({}, false)
      } else {
        setSessionExpired(false);
        // displayEmpLocationErrMsg("Something went wrong!");
        displayEmpLocationErrMsg(empLocationResponse?.response?.data?.message);
        handleEmpLocationListRes({}, false)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpLocationErrMsg("Something went wrong!");
      handleEmpLocationListRes({}, false)
    }finally{
      setLocationLoader(false);
    }
  };

  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")){
      getEmpLocationData();
    }
  }, [locationCurrentPage, rowLocationPerPage]);

  return (
    <>
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowLocationPerPage}
        handleRecordsPerPageChange={handleRecordsLocationPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
        currentPage={locationCurrentPage}
      />
      <div className="twm-candidates-list-wrap">
        <ul>
          {!locationLoader ? (
            empLocationInfo?.length > 0 ? (
              empLocationInfo?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    {/* <div className="twm-media">
                      <div className="twm-media-pic">
                        <GetProfileImage
                          id={item?.id}
                          type={"applicant"}
                        />
                      </div>
                    </div> */}
                    <div className="twm-mid-content">
                      <div>
                      <span
                        className="twm-job-title client-name-location"
                      >
                        <h4><b>{`${item?.locationName}`}</b></h4>
                        {item?.clientName && (<h4><b>{`${item?.clientName ? item?.clientName : ""}`}</b></h4>)}
                      </span>
                      </div>
                    <div className="text-start" style={{width: "50%", display:"inline-block"}}>
                      {/* <span
                        className="twm-job-title"
                      >
                        <h4><b>{`${item?.locationName}`}</b></h4>
                      </span> */}
                        <p className="twm-candidate-address">
                           {item?.address}
                        </p>
                      {
                        item?.suitNumber && 
                        <div>
                        <p className="twm-candidate-address">
                          {item?.suitNumber}
                        </p>
                      </div>
                      }
                      <div>
                        <p className="twm-candidate-address">
                           {item?.city}, {item?.state} {item?.country} {item?.zipCode}
                        </p>
                      </div>
                      </div>
                      <div style={{width: "50%", display:"inline-block", textAlign:"right"}}>
                      <a
                        className="site-button"
                        data-bs-toggle="modal"
                        href={"#sign_up_popup3"}
                        role="button"
                        onClick={() => handlePostJobClick(item.id)}
                      >
                        Post Job
                      </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No results found."
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        <SectionPagination
          currentPage={locationCurrentPage}
          setCurrentPage={setLocationCurrentPage}
          totalPages={paginationLocationData?.totalPages}
        />
        <PostJobPopup
          selectedItemId={selectedItemId}
          empDetailId={empDetailId}
          modalShow={modalShow}
          empDetailsInfo={empDetailsInfo}
          isAdminJobPosted={true} //For refreshed the job list on emp details for admin
          handleRefreshJobList={handleRefreshJobList}
        />
        <AddLocation
        empDetails={empDetailsInfo}
        setLocationCurrentPage={setLocationCurrentPage}
        getEmpLocationData={getEmpLocationData}
      />
      </div>
    </>
  );
};

export default EmpLocationList;
