import { NavLink, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import {
  jobStatusDetails,
  currentUrlDetails,
} from "../../../../../store/reducers/menu";
let current_url = "";


function JobViewTabs() {
  const { role_type , job_status, isLoggedIn } = useSelector(
    (state) => state.menu
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUrl = location.pathname;

  const navigateTo = (base) => {
    if (!currentUrl.includes("/job-grid")) {
      current_url = currentUrl;
      dispatch(currentUrlDetails(currentUrl));
    }
    if (!isLoggedIn) {
      navigate(base, { state: "eOpen" });
      dispatch(jobStatusDetails("eOpen"));
    } else if (role_type.includes("ROLE_USER")) {
      if (currentUrl.includes("/search-job")) {
        navigate(base, { state: "eOpen" });
        dispatch(jobStatusDetails("eOpen"));
      } else if (currentUrl.includes("/applied-job")) {
        navigate(base, { state: "myJobs" });
        dispatch(jobStatusDetails("myJobs"));
      } else if (currentUrl.includes("/ongoing-job")) {
        navigate(base, { state: "onGoing" });
        dispatch(jobStatusDetails("onGoing"));
      } else if (currentUrl.includes("/history-job")) {
        navigate(base, { state: "eHistory" });
        dispatch(jobStatusDetails("eHistory"));
      } else if (

        currentUrl.includes("/job-list") ||
        currentUrl.includes("/job-grid") ||
        currentUrl.includes("/job-calendar-view")
      ) {
        console.log("jobStatus======",base, current_url, currentUrl, job_status);
        // if (base !== "/job-list") {
          navigate(base, { state: job_status });
        // } else {
        //   navigate(current_url, { state: job_status });
        // }
      }
    } else if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
      if (currentUrl.includes("/search-job")) {
        navigate(base, { state: "eOpen" });
        dispatch(jobStatusDetails("eOpen"));
      } else if (currentUrl.includes("/schedule-job")) {
        navigate(base, { state: "eScheduled" });
        dispatch(jobStatusDetails("eScheduled"));
      } else if (currentUrl.includes("/history-job")) {
        navigate(base, { state: "eHistory" });
        dispatch(jobStatusDetails("eHistory"));
      } else if (
        currentUrl.includes("/job-list") ||
        currentUrl.includes("/job-grid") || 
        currentUrl.includes("/job-calendar-view") 
      ) {
        console.log("jobStatus======",base, current_url, job_status);
        // if (base !== "/job-list") {
          navigate(base, { state: job_status });
        // } else {
        //   navigate(current_url, { state: job_status });
        // }
      }
    } else if (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) {
      navigate(base, { state: "All" });
      dispatch(jobStatusDetails("All"));
    } else if (
      currentUrl.includes("/job-list") ||
      currentUrl.includes("/job-grid") ||
      currentUrl.includes("/job-calendar-view")
    ) {
      navigate(base, { state: job_status });
    }
  };

  const jobListNavigation = () => navigateTo("/job-list");
  const jobListGridNavigation = () => navigateTo("/job-grid");
  const jobListGridMapNavigation = () => navigateTo("/job-grid-with-map");
  const jobListCalenderNavigation = () => navigateTo("/job-calendar-view");
  const empListNavigation = () => navigateTo("/comp-list");
  const empGridNavigation = () => navigateTo("/comp-grid");
  const canListNavigation = () => navigateTo("/can-list");
  const canGridNavigation = () => navigateTo("/can-grid");
  const usersListNavigation = () => navigateTo("/users-list");
  const usersGridNavigation = () => navigateTo("/users-grid");

  return (
    <div className="twm-tabs-style-2 mt-5">
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {/* Signup Candidate */}
        {(currentUrl === "/job-list" || currentUrl === "/job-grid" || currentUrl === "/job-calendar-view" || currentUrl === "/job-grid-with-map" || currentUrl === "/search-job" || currentUrl === "/schedule-job" || currentUrl === "/history-job" || currentUrl === "/applied-job" || currentUrl === "/ongoing-job") && (<li className="nav-item" role="presentation">
          {/* <NavLink to="#"> */}
          <button
            className={
              (currentUrl !== "/job-list" || currentUrl !== "/job-list/") &&
              !currentUrl.includes("/job-grid") && !currentUrl.includes("/job-calendar-view")
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#jobs-list"
            type="button"
            onClick={jobListNavigation}
          >
            <i className="fas fa-list" /> List View
          </button>
          {/* </NavLink> */}
        </li>
        )}
        {/* Signup Employer */}
        {(currentUrl === "/job-list" || currentUrl === "/job-grid" || currentUrl === "/job-calendar-view" || currentUrl === "/job-grid-with-map" || currentUrl === "/search-job" || currentUrl === "/schedule-job" || currentUrl === "/history-job" || currentUrl === "/applied-job" || currentUrl === "/ongoing-job") && (<li className="nav-item" role="presentation">
          {/* <NavLink to="#"> */}
          <button
            className={
              currentUrl === "/job-grid" || currentUrl === "/job-grid/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#jobs-grid"
            type="button"
            onClick={jobListGridNavigation}
          >
            <i className="fas fa-th" /> Grid View
          </button>
          {/* </NavLink> */}
        </li>
        )}
        {(currentUrl === "/job-list" || currentUrl === "/job-grid" || currentUrl === "/job-calendar-view" || currentUrl === "/job-grid-with-map" || currentUrl === "/search-job" || currentUrl === "/schedule-job" || currentUrl === "/history-job" || currentUrl === "/applied-job" || currentUrl === "/ongoing-job") && (
          <li className="nav-item" role="presentation">
            {/* <NavLink to="#"> */}
            <button
              className={
                currentUrl === "/job-grid-with-map" ||
                currentUrl === "/job-grid-with-map/"
                  ? "nav-link active"
                  : "nav-link"
              }
              data-bs-toggle="tab"
              data-bs-target="#jobs-grid"
              type="button"
              onClick={jobListGridMapNavigation}
            >
              <i className="fas fa-map-marker-alt" /> Grid With Map View
            </button>
            {/* </NavLink> */}
          </li>
        )}
          {(currentUrl === "/job-list" || currentUrl === "/job-grid" || currentUrl === "/job-calendar-view" || currentUrl === "/job-grid-with-map" || currentUrl === "/search-job" || currentUrl === "/schedule-job" || currentUrl === "/history-job" || currentUrl === "/applied-job" || currentUrl === "/ongoing-job") && (<li className="nav-item" role="presentation">
          {/* <NavLink to="#"> */}
          <button
            className={
              currentUrl === "/job-calendar-view" || currentUrl === "/job-calendar-view/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#jobs-calendar-view"
            type="button"
            onClick={jobListCalenderNavigation}
          >
           <i className="fas fa-calendar" /> Calendar View
          </button>
          {/* </NavLink> */}
        </li>
          )}
        {(currentUrl === "/comp-list" || currentUrl === "/comp-grid") && (<li className="nav-item" role="presentation">
          <button
            className={
              currentUrl === "/comp-list" || currentUrl === "/comp-list/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#comp-list"
            type="button"
            onClick={empListNavigation}
          >
            <i className="fas fa-list" /> List View
          </button>
        </li>
        )}
        {(currentUrl === "/comp-list" || currentUrl === "/comp-grid") && (<li className="nav-item" role="presentation">
          <button
            className={
              currentUrl === "/comp-grid" || currentUrl === "/comp-grid/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#comp-grid"
            type="button"
            onClick={empGridNavigation}
          >
            <i className="fas fa-th" /> Grid View
          </button>
        </li>
        )}
        {(currentUrl === "/can-list" || currentUrl === "/can-grid") && (<li className="nav-item" role="presentation">
          <button
            className={
              currentUrl === "/can-list" || currentUrl === "/can-list/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#can-list"
            type="button"
            onClick={canListNavigation}
          >
            <i className="fas fa-list" /> List View
          </button>
        </li>
        )}
        {(currentUrl === "/can-list" || currentUrl === "/can-grid") && (<li className="nav-item" role="presentation">
          <button
            className={
              currentUrl === "/can-grid" || currentUrl === "/can-grid/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#can-grid"
            type="button"
            onClick={canGridNavigation}
          >
            <i className="fas fa-th" /> Grid View
          </button>
        </li>
        )}
        {(currentUrl === "/users-list" || currentUrl === "/users-grid") && (<li className="nav-item" role="presentation">
          <button
            className={
              currentUrl === "/users-list" || currentUrl === "/users-list/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#users-list"
            type="button"
            onClick={usersListNavigation}
          >
            <i className="fas fa-list" /> List View
          </button>
        </li>
        )}
        {(currentUrl === "/users-list" || currentUrl === "/users-grid") && (<li className="nav-item" role="presentation">
          <button
            className={
              currentUrl === "/users-grid" || currentUrl === "/users-grid/"
                ? "nav-link active"
                : "nav-link"
            }
            data-bs-toggle="tab"
            data-bs-target="#users-grid"
            type="button"
            onClick={usersGridNavigation}
          >
            <i className="fas fa-th" /> Grid View
          </button>
        </li>
        )}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="jobs-list"></div>
        <div className="tab-pane fade" id="jobs-grid"></div>
      </div>
    </div>
  );
}

export default JobViewTabs;
