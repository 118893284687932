import { Route, Routes } from "react-router-dom";
import { candidate } from "../globals/route-names";
import { useSelector } from "react-redux";
import CanDashboardPage from "../app/pannels/candidate/components/can-dashboard";
import CanProfilePage from "../app/pannels/candidate/components/can-profile";
import CanAppliedJobs from "../app/pannels/candidate/components/can-applied-jobs";
import CanMyResumePage from "../app/pannels/candidate/components/can-resume";
import CanSavedJobsPage from "../app/pannels/candidate/components/can-payment-details-jobs";
import CanCVManagerPage from "../app/pannels/candidate/components/can-cv-manager";
import CanJobAlertsPage from "../app/pannels/candidate/components/can-job-alerts";
import CanChangePasswordPage from "../app/pannels/candidate/components/can-change-password";
import CanChatPage from "../app/pannels/candidate/components/can-chat";
import Error404Page from "../app/pannels/public-user/components/pages/error404";
import CanAddLocation from "../app/pannels/candidate/components/can-location";
import CanBankDetails from "../app/pannels/candidate/components/can-bank-details";
import JobSeekerDocuments from "../app/pannels/public-user/components/candidates/documents/jobseekerDocument";
import ProtectedRoute from "./protectedRoute";
import CanProfession from "../app/pannels/candidate/components/can-profession";
import CanPaymentDetails from "../app/pannels/candidate/components/can-payment-details";
import CanPermission from "../app/pannels/candidate/sections/profile/section-can-permission";
import SectionCandidatePaymentInfo from "../app/pannels/candidate/sections/profile/section-can-payment-details";
import CanPaymentDetailsPage from "../app/pannels/candidate/components/can-payment-details-jobs";
import CanMyTeams from "../app/pannels/candidate/components/can-my-teams/can-teams/can-my-teams";
import CanInvites from "../app/pannels/candidate/components/can-my-teams/can-invites/can-invites";

function CandidateRoutes() {
  const { advancedOptionsEnabled } = useSelector((state) => state.menu);
  const ROLE_INSTITUTION_ADMIN = ["ROLE_INSTITUTION_ADMIN"];
  const ROLE_USER = ["ROLE_USER"];
  const ROLE_OPENSTAFF_ADMIN = ["ROLE_OPENSTAFF_ADMIN"];
  const ROLE_ALL = [
    "ROLE_OPENSTAFF_ADMIN",
    "ROLE_OPENSTAFF_FINANCIAL_ADMIN",
    "ROLE_INSTITUTION_POSTER_ADMIN",
    "ROLE_SEEKER_USER_ADMIN",
    "ROLE_USER",
    "ROLE_INSTITUTION_ADMIN",
  ];
  return (
    <Routes>
      <Route path={candidate.DASHBOARD} element={<CanDashboardPage />} />
      <Route
        path={candidate.PROFILE}
        element={
          <ProtectedRoute allowedRoles={ROLE_ALL}>
            <CanProfilePage />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path={candidate.LOCATIONS}
        element={
          <ProtectedRoute allowedRoles={ROLE_INSTITUTION_ADMIN}>
            <CanAddLocation />
          </ProtectedRoute>
        }
      /> */}
      <Route
        path={candidate.PROFESSION}
        element={
          <ProtectedRoute allowedRoles={ROLE_USER}>
            <CanProfession />
          </ProtectedRoute>
        }
      />
      {
       advancedOptionsEnabled && 
        <>
          <Route
            path={candidate.MY_TEAMS}
            element={
              <ProtectedRoute allowedRoles={ROLE_USER}>
                <CanMyTeams />
              </ProtectedRoute>
            }
          />
        <Route
          path={candidate.MY_TEAMS_INVITES}
          element={
          <ProtectedRoute allowedRoles={ROLE_USER}>
            <CanInvites />
          </ProtectedRoute>
          }
        />
        </>
      }
    

      <Route
        path={candidate.BANK_DETAILS}
        element={
          <ProtectedRoute allowedRoles={ROLE_USER} path={candidate.BANK_DETAILS}>
            <CanBankDetails />
          </ProtectedRoute>
        }
      />
      {/* <Route path={candidate.BANK_DETAILS} element={<CanBankDetails />} /> */}
      <Route path={candidate.PERMISSION} element={<CanPermission />} />

      {/* <Route
        path={candidate.DOCUMENTS}
        element={
          <ProtectedRoute allowedRoles={ROLE_USER}>
            <JobSeekerDocuments />
          </ProtectedRoute>
        }
      /> */}
      <Route path={candidate.APPLIED_JOBS} element={<CanAppliedJobs />} />
      <Route path={candidate.RESUME} element={<CanMyResumePage />} />
      <Route path={candidate.SAVED_JOBS} element={<CanSavedJobsPage />} />
      <Route path={candidate.CV_MANAGER} element={<CanCVManagerPage />} />
      <Route path={candidate.ALERTS} element={<CanJobAlertsPage />} />
      <Route
        path={candidate.CHANGE_PASSWORD}
        element={<CanChangePasswordPage />}
      />
      <Route path={candidate.CHAT} element={<CanChatPage />} />
      <Route path={candidate.CHAT} element={<CanChatPage />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
}

export default CandidateRoutes;
