import React, { useEffect, useState } from "react";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import apiCall from "../../../../../../apicall";
import { useSelector } from "react-redux";
import BASE_URL from "../../../../../../config";
import axios from "axios";

import moment from "moment";
const ViewDocumentPopup = ({
  viewDocId,
  tabType,
  applicantDetailsId,
  documentType,
  handleDocApprovedSuccess,
  backgroundForm,
}) => {
const isEnabledBackgroundForm = process.env.REACT_APP_ENABLED_BACKGROUNDFORM === "true";

  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [approveDocLoader, setApproveDocLoader] = useState(false);
  const [documentImageSrc, setDocumentImageSrc] = useState("");
  const [documentUploadType, setDocumentUploadType] = useState("")
  const [backgroundFormData, setBackgroundFormData] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: false,
  });
  const [zoomLevel, setZoomLevel] = useState(1); // Default zoom level

const handleZoomIn = () => {
  setZoomLevel((prev) => Math.min(prev + 0.2, 3)); // Maximum zoom 3x
};

const handleZoomOut = () => {
  setZoomLevel((prev) => Math.max(prev - 0.2, 0.5)); // Minimum zoom 0.5x
};

const handleResetZoom = () => {
  setZoomLevel(1);
};

  const handleFileChange = (e) => {
     const file = e.target.files[0];
    setSelectedFile(file);

    // Determine the file type (image or PDF) and display accordingly
    if (file) {
      const reader = new FileReader();
      console.log("render=====",file.type)
      reader.onloadend = () => {
        if (file?.type === "application/pdf" || file?.type === "text/xml" ) {
          setDocumentUploadType("pdf");
          if(file?.type === "application/pdf"){
            setDocumentImageSrc(reader.result);
          }else{
            setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
          }
        } else {
          setDocumentUploadType("image");
          setDocumentImageSrc(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
    setSelectedFile(e.target.files[0]);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const getBackgroundFormData = async () => {
    const backgroundFormDataResponse = await apiCall(
      `openstaff/users/${applicantDetailsId}`,
      "GET",
      null,
      TOKEN
    );
    if (backgroundFormDataResponse?.data?.id) {
      setIsLoading(false);
      setBackgroundFormData(backgroundFormDataResponse?.data);
    } else {
      setIsLoading(false);
      setBackgroundFormData({});
    }
  };

  const handleViewDocCertificate = async () => {
    setIsLoading(true);
    let data = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    let url;

    if (TOKEN) {
      data.headers["Authorization"] = "Bearer " + TOKEN;
    }

    console.log("tabType-------", documentType, tabType);

    if (tabType === "document" && !documentType) {
      url = `openstaff/documents/${viewDocId}/download`;
    } else if (tabType === "certificate") {
      url = `openstaff/users/${applicantDetailsId}/certificates/${viewDocId}/download`;
    } else if (documentType) {
      //to delete background doc
      url = `openstaff/users/${applicantDetailsId}/background/get`;
    }

    try {
      setIsLoading(true);
      setDocumentImageSrc("");
      const viewDocCertificateResponse = await apiCall(url,"GET", null,TOKEN, true, true);
      console.log("viewDocCertificateResponse", viewDocCertificateResponse);
      //   const viewDocCertificateResponse = await apiCall(url, "GET", null, TOKEN);
      if (viewDocCertificateResponse?.status === 200) {
        setIsLoading(false);
        const blobResponse = await viewDocCertificateResponse.data;
        const reader = new FileReader();

        const fileSignature = await blobResponse.slice(0, 4).arrayBuffer();
        const bytes = new Uint8Array(fileSignature);

        // Detect file type using magic bytes
        const detectFileType = (bytes) => {
          if (bytes[0] === 0x25 && bytes[1] === 0x50) return "application/pdf";   // PDF: "%PDF"
          if (bytes[0] === 0x89 && bytes[1] === 0x50) return "image/png";         // PNG: "\x89PNG"
          if (bytes[0] === 0xFF && bytes[1] === 0xD8) return "image/jpeg";        // JPEG: "\xFF\xD8"
          return blobResponse.type;  // Fallback to blob type
        };

        const fileType = detectFileType(bytes);
        // Handle PDF or Image display
        reader.onloadend = () => {
          if (fileType === "application/pdf") {
            setDocumentUploadType("pdf");
            if(reader?.result?.includes("text/xml")){
              setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
            }else{
              setDocumentImageSrc(reader.result);
            }
          } else if(fileType === "image/png" || fileType === "image/jpeg"){
            setDocumentUploadType("image");
            setDocumentImageSrc(reader.result);
          }else{
            setDocumentUploadType("pdf");
            setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
          }
        };

        reader.readAsDataURL(blobResponse);  // Convert to base64
        // reader.readAsDataURL(blobResponse);

        // if (blobResponse?.type === "application/pdf" || blobResponse?.type === "text/xml") {
        //   reader.onloadend = () => {
        //   setDocumentUploadType("pdf");
        //   console.log("resssss",reader.result)
        //     if(blobResponse?.type === "application/pdf"){
        //       setDocumentImageSrc(reader.result);
        //     }else{
        //       setDocumentImageSrc(reader.result?.replace("data:text/xml;base64,", "data:application/pdf;base64,"));
        //     }
        //   }
        // } else {
        //   reader.onloadend = () => {
        //   setDocumentUploadType("image");
        //   setDocumentImageSrc(reader.result);
        //   };
        // }

          if (!documentType) {
            setIsLoading(false);
          }

        // reader.onloadend = () => {
        //   const imageResponse = reader.result;
        //   setDocumentImageSrc(imageResponse);
        //   if (!documentType) {
        //     setIsLoading(false);
        //   }
        // };

        if (documentType) {
          await getBackgroundFormData();
        }
      } else if (viewDocCertificateResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else if (viewDocCertificateResponse?.response?.status == 500) {
        await getBackgroundFormData();
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(viewDocCertificateResponse?.response?.data?.message)
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  const handleApprove = async () => {
    if (!selectedFile) {
      setErrSuccessMsg({
        message: "Please upload a file before approving.",
        status: false,
      });
      return;
    }

    const allowedFileTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowedFileTypes.includes(selectedFile.type)) {
      setErrSuccessMsg({
        message: "Only PDF or image files are allowed.",
        status: false,
      });
      return;
    }
    try {
      let url;
      setApproveDocLoader(true);
      url = `${BASE_URL}openstaff/users/${applicantDetailsId}/background/post`;

      const formData = new FormData();
      formData.append("file", selectedFile);
      // formData.append("fileType", "ePdf");
      formData.append("fileType", selectedFile.type === "application/pdf" ? "ePdf" : "ePng");
      formData.append("approved", "true");

      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const approvedDocResponse = await axios.post(url, formData, config);
      if (approvedDocResponse?.status === 200) {
        setApproveDocLoader(false);
        // setIsDocApproved(true);
        setErrSuccessMsg({
          message: `Document Approved successfully!`,
          status: true,
        });
        handleDocApprovedSuccess();
      } else if (approvedDocResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setApproveDocLoader(false);
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(approvedDocResponse?.response?.data?.message)
        setApproveDocLoader(false);
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
      setApproveDocLoader(false);
    }
  };

  useEffect(() => {
    if (viewDocId) {
      handleViewDocCertificate();
    }
  }, [viewDocId]);

  return (
    <div
      className="modal fade twm-sign-up"
      //   id="view_applicant_doc"
      id={`${
        tabType === "document"
          ? "view_applicant_doc"
          : tabType === "certificate"
          ? "view_applicant_certificate"
          : ""
      }`}
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header mt-0">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleResetZoom}
            />
            {documentUploadType != 'pdf' && (
              <div className="d-flex justify-content-center">
                <span style={{fontSize : "20px", cursor: "pointer"}} onClick={handleZoomIn}><i className="fas fa-search-plus"></i></span>
                <span style={{fontSize : "20px", cursor: "pointer", marginLeft: "10px"}} onClick={handleZoomOut}><i className="fas fa-search-minus"></i></span>
              </div>
            )}
          </div>
          {documentType ? (
            <div className="modal-body" style={{ textAlign: "center" }}>
              {isLoading ? (
                <Spinner />
              ) : (
                <>
                  {/* <img src={documentImageSrc} alt="Document" /> */}
                  {documentImageSrc && (
                    (documentUploadType === "pdf") ? (
                      <iframe
                        src={documentImageSrc}
                        style={{ width: '100%', height: '600px' }}
                        title="Document Preview"
                      />
                    ) : (
                      <img src={documentImageSrc} alt="Document" style={{ maxWidth: '100%', maxHeight: '600px' }} />
                    ))}
                  {
                      isEnabledBackgroundForm && 
                      <div style={{ marginTop: "20px" }}>
                        <h5>User Information</h5>
                        <p>SSN: {backgroundFormData?.last4SSN}</p>
                        <p>First Name: {backgroundFormData?.firstName}</p>
                        <p>Last Name: {backgroundFormData?.lastName}</p>
                        <p>
                          Date of Birth:
                          {moment(backgroundFormData?.dateOfBirth).format(
                            "MM/DD/YYYY"
                          )}
                        </p>
                        <p>Address: {backgroundFormData?.address}</p>
                        <p>Phone Number: {backgroundFormData?.phoneNumber}</p>
                        <p>Email Address: {backgroundFormData?.email}</p>
                      </div>
                  }
                  
                  {backgroundForm?.applicationDocumentStatus !== "approved" && (
                     isEnabledBackgroundForm && 
                      (
                      <>
                        <div style={{ marginTop: "20px" }}>
                          <input
                            type="file"
                            accept="image/*,application/pdf"
                            onChange={handleFileChange}
                          />
                        </div>
                        <button
                          className="btn btn-primary"
                          onClick={handleApprove}
                          style={{ marginTop: "20px" }}
                        >
                          {approveDocLoader ? <Spinner /> : "Approve"}
                        </button>
                      </>
                      )
                  )}
                </>
              )}
            </div>
          ) : (
            <div className="modal-body" style={{ textAlign: "center", overflow: "auto", maxHeight: "600px" }}>
              {isLoading ? (
                <Spinner />
              ) : (
                documentImageSrc && (
                  documentUploadType === 'pdf' ? (
                    <iframe
                      src={documentImageSrc}
                      style={{ width: '100%', height: '600px' }}
                      title="Document Preview"
                    />
                  ) : (
                    <img src={documentImageSrc} alt="#Document Image" style={{ maxWidth: '100%', maxHeight: '600px', transform: `scale(${zoomLevel})`, transformOrigin: "center" }} />
                  )
                )
              )}
            </div>
          )}
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default ViewDocumentPopup;
