import JobPostForm from "../register/JobPostForm";
import { useState } from "react";
function PostJobPopup({ selectedItemId, empDetailId, modalShow, empDetailsInfo, isAdminJobPosted, handleRefreshJobList}) {
  const [activeTab, setActiveTab] = useState("single"); // State for active tab

  const handleClosePopup = () => {
    const modal = document.getElementById("sign_up_popup3");
    const modalInstance = window.bootstrap.Modal.getInstance(modal);
    modalInstance.hide();
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="sign_up_popup3"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "800px"}}>
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel">
                  Post Job
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="twm-tabs-style-2">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === "single" ? "active" : ""}`}
                        // data-bs-toggle="tab"
                        // data-bs-target="#sign-single"
                        type="button"
                        onClick={() => handleTabChange("single")}
                      >
                        <i className="fas fa-user-tie" />
                        Single
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className={`nav-link ${activeTab === "multiple" ? "active" : ""}`}
                        // data-bs-toggle="tab"
                        // data-bs-target="#sign-multiple"
                        type="button"
                        onClick={() => handleTabChange("multiple")}
                      >
                        <i className="fas fa-building" />
                        Multiple
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {/* <div className="tab-pane fade show active" id="sign-single"> */}
                      <JobPostForm
                        role_type={activeTab === "single" ? "Single" : "Multiple"}
                        // role_type={"Single"}
                        selectedItemId={selectedItemId}
                        empDetailId={empDetailId}
                        handleClosePopup={handleClosePopup}
                        modalShow={modalShow}
                        empDetailsInfo={empDetailsInfo}
                        isAdminJobPosted={isAdminJobPosted}
                        handleRefreshJobList={handleRefreshJobList}
                      />
                    {/* </div> */}
                    {/* <div className="tab-pane fade" id="sign-multiple">
                      <JobPostForm
                        role_type={"Multiple"}
                        selectedItemId={selectedItemId}
                        empDetailId={empDetailId}
                        handleClosePopup={handleClosePopup}
                        modalShow={modalShow}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              {/* <div className="modal-footer">
                <span className="modal-f-title">Login or Sign up with</span>
                <ul className="twm-modal-social">
                  <li>
                    <a
                      href="https://www.facebook.com/"
                      className="facebook-clr"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/" className="twitter-clr">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="https://in.linkedin.com/" className="linkedin-clr">
                      <i className="fab fa-linkedin-in" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/" className="google-clr">
                      <i className="fab fa-google" />
                    </a>
                  </li>
                </ul>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostJobPopup;
