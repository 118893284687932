import JobZImage from "../jobz-img";
import { NavLink, useLocation } from "react-router-dom";
import { candidate, publicUser } from "../../../globals/route-names";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout, userDetails, roleType } from "../../../store/reducers/menu";
import ConfirmationLogoutAccount from "./confirmationLogoutAccount";
import { formatCompanyName } from "../../../globals/fomat-comapny-name";
import GetProfileImage from "../getProfileImage";

function Header1({ _config }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { isLoggedIn, userInfo, role_type, company_logo, theme_colors,small_company_logo, userLogo, advancedOptionsEnabled } = useSelector(
    (state) => state.menu
  );
  useEffect(()=>{
    theme_colors.map((theme)=>{
      if(theme.title=="site-bg-primary"){
        document.documentElement.style.setProperty('--site-bg-primary', theme.mapField)
      }
      if(theme.title=="site-bg-secondary"){
        document.documentElement.style.setProperty('--site-bg-secondary', theme.mapField)
      }
      if(theme.title=="site-bg-third"){
        document.documentElement.style.setProperty('--site-bg-third', theme.mapField)
      }
      if(theme.title=="site-color-primary"){
        document.documentElement.style.setProperty('--site-color-primary', theme.mapField)
      }
      if(theme.title=="site-color-secondary"){
        document.documentElement.style.setProperty('--site-color-secondary', theme.mapField)
      }
      if(theme.title=="site-bg-color-active"){
        document.documentElement.style.setProperty('--site-bg-color-active', theme.mapField)
      }
    })
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = `${company_logo}`;
    document.head.appendChild(link);
  },[theme_colors])
  useEffect(()=>{
  const link =document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = small_company_logo;
  document.getElementsByTagName('head')[0].appendChild(link);
  document.title = formatCompanyName();
  },[small_company_logo])
  const [menuActive, setMenuActive] = useState(false);

  function handleNavigationClick() {
    setMenuActive(!menuActive);
  }

  const handleLogout = () => {
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(roleType([]));
    navigate("/index");
  };

  const viewProfile = () => {
    navigate("/candidate/profile");
  };

  const handleNavigationLogo = () => {
    if (role_type?.includes("ROLE_OPENSTAFF_ADMIN") || role_type?.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) {
      navigate("/job-list");
    } else if (
      role_type?.includes("ROLE_INSTITUTION_ADMIN")  ||
      role_type?.includes("ROLE_USER") 
    ) {
      navigate("/search-job");
    } else if (
      role_type?.includes("ROLE_INSTITUTION_POSTER_ADMIN") 
    ) {
      navigate("/comp-list");
    } else if (
      role_type?.includes("ROLE_SEEKER_USER_ADMIN") 
    ) {
      navigate("/can-list");
    }
    else {
      navigate("/index");
    }
  };

  const GetProfileLogo = () => {
    return (
        userLogo ?
            <img
            type="file"
            name="myfile"
            id="file-uploader"
            src={userLogo}
            alt="Image"
            // style={{ height: "100%" }}
          />
          :
          <div className="profile-icon-color" style={{"width":"50px", "textAlign": "center", "height": "50px", "borderRadius": "50%", "color": "#ffffff", "paddingBlock": "13px", "paddingInline": "12px"}}>{`${userInfo?.firstName?.charAt(0)}${userInfo?.lastName?.charAt(0)}`}</div>
    )
  }
  return (
    <>
      <header
        className={
          "site-header " +
          _config.style +
          " mobile-sider-drawer-menu " +
          (menuActive ? "active" : "")
        }
      >
        <div className="sticky-header main-bar-wraper navbar-expand-lg is-fixed">
          <div className="main-bar color-fill">
            <div className="container-fluid clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <span
                    onClick={handleNavigationLogo}
                    style={{ cursor: "pointer" }}
                  >
                    {_config.withBlackLogo ? (
                      <JobZImage src="images/openStaffLogo.png" alt="" />
                    ) : _config.withWhiteLogo ? (
                      <JobZImage src="images/openStaffLogo.png" alt="" />
                    ) : company_logo?
                    <img src={company_logo} id="company-logo" height ={'300px'} width={'auto'}/>
                    : _config.withLightLogo ? (
                      <>
                        <JobZImage
                          //id="skin_header_logo_light"
                          src="images/openStaffLogo.png"
                          width="50px"
                          alt=""
                          //className="default-scroll-show"
                        />
                        <JobZImage
                          //id="skin_header_logo"
                          src="images/openStaffLogo.png"
                          width="50px"
                          alt=""
                          //className="on-scroll-show"
                        />
                      </>
                    ) : (
                      <JobZImage
                        //id="skin_header_logo"
                        src="images/openStaffLogo.png"
                        width="50px"
                        alt="OpenStaff"
                      />
                    )}
                  </span>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
                onClick={handleNavigationClick}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* MAIN Vav */}

              <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                <ul className=" nav navbar-nav">
                  {isLoggedIn && (<li className="has-child profile-icon-logo profile-icon-logo-resp">
                    <a

                      className={`${currentUrl?.includes("/profile") ? "active" : ""}`}
                    >
                      <GetProfileLogo />
                     
                     {/* <GetProfileImage id={null} type={"applicant"} logoType={"profile-logo"} headerLogo={"header-logo"} /> */}
                    </a>
                    <ul className="sub-menu">
                      <li>
                        <NavLink to={"/candidate/profile"}>Profile</NavLink>
                      </li>
                      <li>

                        <a
                          className=""
                          data-bs-toggle="modal"
                          href={"#logout_account_confirmation_popup"}
                          role="button"
                        >
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                  )}
                  {role_type?.includes("ROLE_USER") && (
                    <li className="has-child">
                      <NavLink
                        to={publicUser.jobs.SEARCH_JOB}
                        className={
                          currentUrl?.includes(publicUser.jobs.SEARCH_JOB)
                            ? "active"
                            : ""
                        }
                      >
                        Search Jobs
                      </NavLink>
                    </li>
                  )}
                  {role_type?.includes("ROLE_INSTITUTION_ADMIN") && (
                    <li className="has-child">
                      <NavLink
                        to={publicUser.jobs.POST}
                        className={
                          currentUrl?.includes(publicUser.jobs.POST)
                            ? "active"
                            : ""
                        }
                      >
                        Post Job
                      </NavLink>
                    </li>
                  )}

                  {currentUrl !== "/" &&
                    currentUrl !== "/index" &&
                    currentUrl !== "/sign-up" &&
                    !(role_type?.includes("ROLE_OPENSTAFF_ADMIN") || role_type?.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type?.includes("ROLE_INSTITUTION_POSTER_ADMIN") || role_type?.includes("ROLE_SEEKER_USER_ADMIN"))&&
                    role_type?.length !== 0 && (
                      <li className="has-child">
                        <a
                          // href="#"
                          className={
                            currentUrl?.includes(publicUser.jobs.APPLIED_JOBS) ||
                            currentUrl?.includes(publicUser.jobs.ONGOING_JOB) ||
                            (currentUrl?.includes(publicUser.jobs.SEARCH_JOB) && role_type?.includes('ROLE_INSTITUTION_ADMIN')) ||
                            currentUrl?.includes(publicUser.jobs.SCHEDULE_JOB) ||
                            currentUrl?.includes(publicUser.jobs.HISTORY_JOBS)
                              ? "active"
                              : ""
                          }
                        >
                          My Jobs
                        </a>
                        <ul className="sub-menu">
                          {role_type?.includes("ROLE_USER") ? (
                            <>
                              <li>
                                <NavLink to={publicUser.jobs.APPLIED_JOBS}>
                                  Applied
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={publicUser.jobs.ONGOING_JOB}>
                                  Ongoing
                                </NavLink>
                              </li>
                            </>
                          ) : role_type?.includes("ROLE_INSTITUTION_ADMIN") ? (
                            <>
                              <li>
                                <NavLink to={publicUser.jobs.SEARCH_JOB}>
                                  Open
                                </NavLink>
                              </li>
                              <li>
                                <NavLink to={publicUser.jobs.SCHEDULE_JOB}>
                                  Scheduled
                                </NavLink>
                              </li>
                            </>
                          ) : (
                            ""
                          )}

                          <li>
                            <NavLink to={publicUser.jobs.HISTORY_JOBS}>
                              History
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    )}
                  {role_type?.includes("ROLE_USER") && !userInfo?.isPrivateEmployee && (
                    <li className="has-child">
                      <NavLink
                        to={candidate.DOCUMENTS}
                        className={
                          currentUrl?.includes(candidate.DOCUMENTS)
                            ? "active"
                            : ""
                        }
                      >
                        Documents
                      </NavLink>
                    </li>
                  )}
                  {/* {currentUrl !== "/" &&
                    currentUrl !== "/index" &&
                    currentUrl !== "/sign-up" &&
                    role_type?.includes("ROLE_OPENSTAFF_ADMIN") && (
                      <li className="has-child">
                        <a href="#">Jobs</a>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to={publicUser.jobs.GRID}>
                              Jobs Grid
                            </NavLink>
                          </li>
                          {!role_type?.includes("ROLE_INSTITUTION_ADMIN") && (
                            <li>
                              <NavLink to={publicUser.jobs.GRID_MAP}>
                                Jobs Grid with Map
                              </NavLink>
                            </li>
                          )}

                          <li>
                            <NavLink to={publicUser.jobs.LIST}>
                              Jobs List
                            </NavLink>
                          </li>
                          {role_type?.includes("ROLE_INSTITUTION_ADMIN") && (
                            <li>
                              <NavLink to={publicUser.jobs.POST}>
                                Post Job
                              </NavLink>
                            </li>
                          )}
                        </ul>
                      </li>
                    )} */}

                  {(role_type?.includes("ROLE_OPENSTAFF_ADMIN")  || role_type?.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type?.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                    <li className="has-child">
                      <NavLink
                          to={publicUser.jobs.LIST}
                          className={
                            currentUrl?.includes(publicUser.jobs.GRID) ||
                            currentUrl?.includes(publicUser.jobs.GRID_MAP) ||
                            currentUrl?.includes(publicUser.jobs.CALENDAR_VIEW) ||
                            currentUrl?.includes(publicUser.jobs.LIST) || 
                            currentUrl?.includes(publicUser.jobs.DETAIL1)
                              ? "active"
                              : ""
                          }
                        >
                         Jobs
                        </NavLink>
                      {/* <a
                        // href="#"
                        className={
                          currentUrl?.includes(publicUser.jobs.GRID) ||
                          currentUrl?.includes(publicUser.jobs.GRID_MAP) ||
                          currentUrl?.includes(publicUser.jobs.CALENDER_VIEW) ||
                          currentUrl?.includes(publicUser.jobs.LIST) || 
                          currentUrl?.includes(publicUser.jobs.DETAIL1)
                            ? "active"
                            : ""
                        }
                      >
                        Jobs
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={publicUser.jobs.GRID}>Jobs Grid</NavLink>
                        </li>
                        <li>
                          <NavLink to={publicUser.jobs.GRID_MAP}>
                            Jobs Grid with Map
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={publicUser.jobs.CALENDER_VIEW}>
                            Jobs Calender View
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={publicUser.jobs.LIST}>Jobs List</NavLink>
                        </li>
                      </ul> */}
                    </li>
                  )}
                  {(role_type?.includes("ROLE_OPENSTAFF_ADMIN") || role_type?.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type?.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && (
                    <li className="has-child">
                      <NavLink
                          to={publicUser.employer.LIST}
                          className={
                            currentUrl?.includes(publicUser.employer.GRID) ||
                            currentUrl?.includes(publicUser.employer.LIST) ||
                            currentUrl?.includes(publicUser.employer.DETAIL1)
                              ? "active"
                              : ""
                          }
                        >
                         Companies
                        </NavLink>
                      {/* <a
                        // href="#"
                        className={
                          currentUrl?.includes(publicUser.employer.GRID) ||
                          currentUrl?.includes(publicUser.employer.LIST) ||
                          currentUrl?.includes(publicUser.employer.DETAIL1)
                            ? "active"
                            : ""
                        }
                      >
                        Employers
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <NavLink to={publicUser.employer.GRID}>
                            Employers Grid
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={publicUser.employer.LIST}>
                            Employers List
                          </NavLink>
                        </li> */}
                        {/* <li className="has-child"><a href="#">Employers Detail</a>
                                                    <ul className="sub-menu">
                                                        <li><NavLink to={publicUser.employer.DETAIL1}>Detail 1</NavLink>
                                                        </li><li><NavLink to={publicUser.employer.DETAIL2}>Detail 2</NavLink>
                                                        </li></ul>
                                                </li> */}
                      {/* </ul> */}
                    </li>
                  )}

                  {/* <li className="has-child"><a href="#">Pages</a>
                                        <ul className="sub-menu">
                                            <li><NavLink to={publicUser.pages.ABOUT}>About Us</NavLink></li>
                                            <li><NavLink to={publicUser.pages.PRICING}>Pricing</NavLink></li>
                                            <li><NavLink to={publicUser.pages.ERROR404}>Error-404</NavLink></li>
                                            <li><NavLink to={publicUser.pages.FAQ}>FAQ's</NavLink></li>
                                            <li><NavLink to={publicUser.pages.CONTACT}>Contact Us</NavLink></li>
                                            <li><NavLink to={publicUser.pages.MAINTENANCE}>Under Maintenance</NavLink></li>
                                            <li><NavLink to={publicUser.pages.COMING}>Coming soon</NavLink></li>
                                            <li><NavLink to={publicUser.pages.LOGIN}>Login</NavLink></li>
                                            <li><NavLink to={publicUser.pages.AFTER_LOGIN}>After Login</NavLink></li>
                                            <li><NavLink to={publicUser.pages.ICONS}>Icons</NavLink></li>
                                        </ul>
                                    </li> */}
                  {(role_type?.includes("ROLE_OPENSTAFF_ADMIN") || role_type?.includes("ROLE_SEEKER_USER_ADMIN")) && (
                    <>
                      <li className="has-child">
                      <NavLink
                          to={publicUser.candidate.LIST}
                          className={
                            currentUrl?.includes(publicUser.candidate.GRID) ||
                            currentUrl?.includes(publicUser.candidate.LIST) ||
                            currentUrl?.includes(publicUser.candidate.DETAIL1)
                              ? "active"
                              : ""
                          }
                        >
                         Candidates
                        </NavLink>
                        {/* <a
                          // href="#"
                          className={
                            currentUrl?.includes(publicUser.candidate.GRID) ||
                            currentUrl?.includes(publicUser.candidate.LIST) ||
                            currentUrl?.includes(publicUser.candidate.DETAIL1)
                              ? "active"
                              : ""
                          }
                        >
                          Candidates
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to={publicUser.candidate.GRID}>
                              Candidates Grid
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.candidate.LIST}>
                              Candidates List
                            </NavLink>
                          </li> */}
                          {/* <li className="has-child"><a href="#">Candidate Detail</a>
                                                    <ul className="sub-menu">
                                                        <li><NavLink to={publicUser.candidate.DETAIL1}>Detail 1</NavLink>
                                                        </li><li><NavLink to={publicUser.candidate.DETAIL2}>Detail 2</NavLink>
                                                        </li></ul>
                                                </li> */}
                        {/* </ul> */}
                      </li>
                    </>
                  )}
                  {role_type?.includes("ROLE_OPENSTAFF_ADMIN") && (
                    <>
                      <li className="has-child">
                        <NavLink
                          to={publicUser.users.LIST}
                          className={
                            currentUrl?.includes(publicUser.users.GRID) ||
                              currentUrl?.includes(publicUser.users.LIST) ||
                              currentUrl?.includes(publicUser.users.DETAIL1)
                              ? "active"
                              : ""
                          }
                        >
                          Users
                        </NavLink>
                        {/* <a
                          // href="#"
                          className={
                            currentUrl?.includes(publicUser.users.GRID) ||
                            currentUrl?.includes(publicUser.users.LIST) ||
                            currentUrl?.includes(publicUser.users.DETAIL1)
                              ? "active"
                              : ""
                          }
                        >
                          Users
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to={publicUser.users.GRID}>
                              Users Grid
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.users.LIST}>
                              Users List
                            </NavLink>
                          </li>
                        </ul> */}
                      </li>
                    </>
                  )}

                {(role_type?.includes("ROLE_OPENSTAFF_ADMIN") || role_type?.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && (
                    <li className="has-child">
                        <NavLink
                          to={publicUser.PAYMENT.PAYMENT_HISTORY}
                          className={
                            currentUrl?.includes(publicUser.PAYMENT.PAYMENT_HISTORY) ? "active" : ""
                          }
                        >
                         Payment History
                        </NavLink>
                      </li>
                  )}
                  {/* <li className="has-child"><a href="#">Blog</a>
                                        <ul className="sub-menu">
                                            <li><NavLink to={publicUser.blog.GRID1}>Blog</NavLink></li>
                                            <li><NavLink to={publicUser.blog.GRID2}>Blog Grid</NavLink></li>
                                            <li><NavLink to={publicUser.blog.GRID3}>Blog Grid-2</NavLink></li>
                                            <li><NavLink to={publicUser.blog.LIST}>Blog List</NavLink></li>
                                            <li><NavLink to={publicUser.blog.DETAIL}>Blog Detail</NavLink></li>
                                        </ul>
                                    </li> */}
                  {isLoggedIn ? (
                    <>
                      {role_type?.includes("ROLE_INSTITUTION_ADMIN") && (
                        <li className="has-child">
                        <a
                          // href="#"
                          className={
                            (currentUrl?.includes("/locations") || currentUrl?.includes("/payment-history") || currentUrl?.includes("/company-details") || currentUrl?.includes("/manage-team") ) ? "active" : ""
                          }
                        >
                          {role_type?.includes("ROLE_INSTITUTION_ADMIN")
                            ? userInfo?.institutionName
                            : `${userInfo?.firstName} ${userInfo?.lastName}`}
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <NavLink to={publicUser.COMPANY_DETAILS}>Company Details</NavLink>
                          </li>
                          <li>
                            <NavLink to={publicUser.LOCATIONS}>Locations</NavLink>
                          </li>
                          {userInfo?.manageTeam && advancedOptionsEnabled  && (<li>
                            <NavLink className={(currentUrl?.includes("/manage-team-employee") || currentUrl?.includes("/manage-team-trusted-worker") || currentUrl?.includes("/manage-team-trusted-companies") ) ? "active" : ""} to={publicUser.MANAGE_TEAM_EMPLOYEE}>My Team</NavLink>
                          </li>
                          )}
                          <li>
                            <NavLink to={publicUser.PAYMENT.PAYMENT_HISTORY}>Payment History</NavLink>
                          </li>
                          {/* <li>
                            <NavLink to={"/candidate/profile"}>Profile</NavLink>
                          </li>
                          <li>
                            <a
                              className=""
                              data-bs-toggle="modal"
                              href={"#logout_account_confirmation_popup"}
                              role="button"
                              // onClick={() =>
                              //   openDeletionConfirmation(jobDetailsInfo?.id)
                              // }
                            >
                              Logout
                            </a>
                          </li> */}
                        </ul>
                      </li>
                    )}
                    </>
                  ) : (
                    ""
                  )}
                  {(role_type?.includes("ROLE_OPENSTAFF_ADMIN") || role_type?.includes("ROLE_USER") || 
  role_type?.includes("ROLE_INSTITUTION_ADMIN")) && (
                    <li className="has-child">
                        <NavLink
                          to={candidate.CHATS}
                          className={
                            currentUrl?.includes(candidate.CHATS) ? "active" : ""
                          }
                        >
                          Chat
                        </NavLink>
                      </li>
                  )}
                </ul>
              </div>
                 
              {isLoggedIn && (
                <div className="profileContainer">
                <div className="profileIcon" >
                   <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                   <ul className=" nav navbar-nav">
                   <>
                    <li className="has-child profile-icon-logo" >
                      {/* <GetProfileImage id={null} type={"applicant"} logoType={"profile-logo"} headerLogo={"header-logo"}/> */}
                      <GetProfileLogo />
                      <ul id="sub-menu" className="sub-menu">
                        <li>
                          <NavLink to={"/candidate/profile"}>Profile</NavLink>
                        </li>
                        <li>
                       
                          <a
                            className=""
                            data-bs-toggle="modal"
                            href={"#logout_account_confirmation_popup"}
                            role="button"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  </>
                   </ul>
                   </div>
                   </div>
                </div>
              )}
              {/* Header Right Section*/}
              <div className="extra-nav header-2-nav">
                {/* <div className="extra-cell">
                  <div className="header-search">
                    <a href="#search" className="header-search-icon">
                      <i className="feather-search" />
                    </a>
                  </div>
                </div> */}
                {isLoggedIn ? (
                  ""
                ) : (
                  <div className="extra-cell">
                    <div className="header-nav-btn-section">
                      <div className="twm-nav-btn-left">
                        <NavLink
                          className="twm-nav-sign-up site-button"
                          //data-bs-toggle="modal"
                          to="/sign-up"
                          //href="#sign_up_popup"
                          role="button"
                        >
                          <i className="feather-log-in" /> Sign Up
                        </NavLink>
                      </div>
                      <div className="twm-nav-btn-right">
                        <a
                          className="twm-nav-post-a-job site-button"
                          data-bs-toggle="modal"
                          href="#sign_up_popup2"
                          role="button"
                        >
                          <i className="feather-log-in" /> Sign In
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* SITE Search */}
          <div id="search">
            <span className="close" />
            <form
              role="search"
              id="searchform"
              action="/search"
              method="get"
              className="radius-xl"
            >
              <input
                className="form-control"
                name="q"
                type="search"
                placeholder="Type to search"
              />
              <span className="input-group-append">
                <button type="button" className="search-btn">
                  <i className="fa fa-paper-plane" />
                </button>
              </span>
            </form>
          </div>
        </div>
      </header>
      <ConfirmationLogoutAccount />
    </>
  );
}

export default Header1;
