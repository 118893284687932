import JobZImage from "../../../../common/jobz-img";
import { NavLink, useLocation } from "react-router-dom";
import { setMenuActive } from "../../../../../globals/constants";
import {
  candidate,
  canRoute,
  publicUser,
} from "../../../../../globals/route-names";
import { useDispatch, useSelector } from "react-redux";
import GetProfileImage from "../../../../common/getProfileImage";
import { useState } from "react";
import BASE_URL from "../../../../../config";
import axios from "axios";
import Spinner from "../../../../common/spinner";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import { fetchuserLogo } from "../../../../common/fetch-user-logo";

function CanSidebarSection() {
  const { userInfo, TOKEN, role_type, advancedOptionsEnabled } = useSelector((state) => state.menu);
  const currentpath = useLocation().pathname;
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };
  // const StarRating = ({ rating }) => {
  //   const stars = [];
  //   const roundedRating = Math.round(rating);

  //   for (let i = 0; i < 5; i++) {
  //     if (i < roundedRating) {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="fas fa-star"
  //           style={{ color: "#128c5e" }}
  //         />
  //       );
  //     } else {
  //       stars.push(
  //         <i
  //           key={i}
  //           className="fas fa-star-half-alt"
  //           style={{ color: "lightgrey" }}
  //         />
  //       );
  //     }
  //   }
  //   return <div>{stars}</div>;
  // };
  const StarRating = ({ rating }) => {
    const stars = [];

    // Iterate over 5 stars
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        // Full star if rating is greater than or equal to current index + 1
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: '#128c5e' }}
          />
        );
      } else if (rating >= i + 0.5) {
        // Half star if rating is greater than or equal to current index + 0.5
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: '#128c5e' }}
          />
        );
      } else {
        // Empty star otherwise
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: 'lightgrey' }}
          />
        );
      }
    }

    return <div>{stars}</div>;
  };

  const handleUpload = async () => {
    if (selectedFile) {
      //   const updateAvatarResponse = await apiCall("avatar", "PUT", selectedFile, TOKEN);
      //     // You can add code here to upload the selectedFile to your server or storage
      //     console.log("Uploading image:", updateAvatarResponse);

      try {
        setIsLoading(true);
        const url = `${BASE_URL}avatar`;

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("fileType", "ePng");
        const config = {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${TOKEN}`,
          },
        };

        const response = await axios.post(url, formData, config);
        console.log("Upload successful:", response);

        if (response?.status == 200) {
          setIsLoading(false);
          setSessionExpired(false);
          setErrSuccessMsg({
            message: "Profile image updated successfully!",
            status: true,
          });
          fetchuserLogo(dispatch, TOKEN)
        } else if (response?.response?.status == 401) {
          setSessionExpired(true);
          displayErrMsg("Your session has been expired!!");
        } else {
          // displayErrMsg("Something went wrong!");
          displayErrMsg(response?.response?.data?.message)
        }
      } catch (error) {
        displayErrMsg("Something went wrong!");
        console.error("Upload failed:", error);
        // Handle failure response if needed
      }
    } else {
      console.log("No file selected.");
    }
  };

  const handleUploadButtonClick = () => {
    // Trigger file input click when the "Upload Photo" button is clicked
    document.getElementById("fileInput-profile-logo").click();
  };

  return (
    <>
      <div className="twm-candidate-profile-pic">
        {selectedFile ? (
          <img src={URL.createObjectURL(selectedFile)} alt="Selected File" />
        ) : (
          <GetProfileImage
            id={null}
            type={"applicant"}
            logoType={"profile-logo"}
          />
        )}
        {/* <GetProfileImage  id={null} type={'applicant'}/>     */}
        <div className="upload-btn-wrapper">
          {/* Hidden file input element */}
          <div id="upload-image-grid" />
          <input
            type="file"
            id="fileInput-profile-logo"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {/* Button to trigger file input click */}
          <button
            className="site-button button-sm"
            onClick={handleUploadButtonClick}
          >
            Upload Image
          </button>
        </div>
      </div>
      <button
        className="site-button button-sm"
        style={{ top: "-20px", left: "40%" }}
        onClick={handleUpload}
        disabled={!selectedFile}
      >
        {isLoading ? <Spinner /> : "Save"}
      </button>

      <div className="twm-mid-content text-center">
        {!(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN") || role_type.includes("ROLE_INSTITUTION_ADMIN")) && (
          <>
            <a id="review-list-styling" className="m-b10"
              data-bs-toggle="modal"
              href="#review_list_popup"
              role="button">
            <StarRating
              rating={
                role_type.includes("ROLE_USER")
                  ? userInfo?.applicantAverageRating
                  : userInfo?.instituteAverageRating
              }
            />
            <span>
              {
                role_type.includes("ROLE_USER")
                  ? `${userInfo?.applicantNumberOfReviews} Reviews`
                  : `${userInfo?.instituteNumberOfReviews} Reviews`
              }
            </span>
            </a>
          </>
        )}

        {/* <NavLink to="#" className="twm-job-title"> */}
          <h4>{`${userInfo?.firstName} ${userInfo?.lastName}`} </h4>
        {/* </NavLink> */}
        {/* <p>IT Contractor</p> */}
        <p></p>
      </div>
      <div className="twm-nav-list-1">
        <ul>
          {/* <li
            className={setMenuActive(
              currentpath,
              canRoute(candidate.DASHBOARD)
            )}
          >
            <NavLink to={canRoute(candidate.DASHBOARD)}>
              <i className="fa fa-tachometer-alt" />
              Dashboard
            </NavLink>
          </li> */}
          <li
            className={setMenuActive(currentpath, canRoute(candidate.PROFILE))}
          >
            <NavLink to={canRoute(candidate.PROFILE)}>
              <i className="fa fa-user" />
              My Profile
            </NavLink>
          </li>
          {/* {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
            <>
              <li
                className={setMenuActive(
                  currentpath,
                  canRoute(candidate.LOCATIONS)
                )}
              >
                <NavLink to={canRoute(candidate.LOCATIONS)}>
                  <i className="fa fa-map-marker-alt" />
                  Locations
                </NavLink>
              </li>
              <li
                className={setMenuActive(
                  currentpath,
                  canRoute(candidate.COMPANY_LOGO)
                )}
              >
                <NavLink to={canRoute(candidate.COMPANY_LOGO)}>
                  <i className="fa fa-user" />
                  Company Logo
                </NavLink>
              </li>
            </>
          )} */}
          {role_type.includes("ROLE_USER") && !userInfo?.isPrivateEmployee && (
            <li
              className={setMenuActive(
                currentpath,
                canRoute(candidate.BANK_DETAILS)
              )}
            >
              <NavLink to={canRoute(candidate.BANK_DETAILS)}>
                <i className="fa fa-money-check" />
                Bank Details
              </NavLink>
            </li>
          )}
          {role_type.includes("ROLE_USER") && (
            <li
              className={setMenuActive(
                currentpath,
                canRoute(candidate.PROFESSION)
              )}
            >
              <NavLink to={canRoute(candidate.PROFESSION)}>
                <i className="fa fa-user-tie" />
                Professions
              </NavLink>
            </li>
          )}
          {role_type.includes("ROLE_USER") && advancedOptionsEnabled  && (
            <li
              className={setMenuActive(
                currentpath,
                canRoute(candidate.MY_TEAMS) || canRoute(candidate.MY_TEAMS_INVITES)
              )}
            >
              <NavLink to={canRoute(candidate.MY_TEAMS)}>
                <i className="fa fa-users" />
                My Teams
              </NavLink>
            </li>
          )}
           {role_type.includes("ROLE_USER") && (
            <li
              className={setMenuActive(
                currentpath,
                canRoute(candidate.PERMISSION)
              )}
            >
              <NavLink to={canRoute(candidate.PERMISSION)}>
                <i className="fas fa-lock" />
                Permission
              </NavLink>
            </li>
          )}
          {/* {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
            <li
              className={setMenuActive(
                currentpath,
                canRoute(candidate.PAYMENT_DETAILS)
              )}
            >
              <NavLink to={canRoute(candidate.PAYMENT_DETAILS)}>
                <i className="fas fa-credit-card" />
                Payment Details
              </NavLink>
            </li>
          )} */}
          {/* {role_type === "ROLE_USER" && (
            <li
              className={setMenuActive(
                currentpath,
                canRoute(candidate.DOCUMENTS)
              )}
            >
              <NavLink to={canRoute(candidate.DOCUMENTS)}>
                <i className="fa fa-suitcase" />
                Documents
              </NavLink>
            </li>
          )} */}

          {/* <li
            className={setMenuActive(
              currentpath,
              canRoute(candidate.APPLIED_JOBS)
            )}
          >
            <NavLink to={canRoute(candidate.APPLIED_JOBS)}>
              <i className="fa fa-suitcase" />
              Applied Jobs
            </NavLink>
          </li> */}
          {/* <li
            className={setMenuActive(currentpath, canRoute(candidate.RESUME))}
          >
            <NavLink to={canRoute(candidate.RESUME)}>
              <i className="fa fa-receipt" />
              My Resume
            </NavLink>
          </li> */}
          {/* <li
            className={setMenuActive(
              currentpath,
              canRoute(candidate.SAVED_JOBS)
            )}
          >
            <NavLink to={canRoute(candidate.SAVED_JOBS)}>
              <i className="fa fa-file-download" />
              Saved Jobs
            </NavLink>
          </li> */}
          {/* <li
            className={setMenuActive(
              currentpath,
              canRoute(candidate.CV_MANAGER)
            )}
          >
            <NavLink to={canRoute(candidate.CV_MANAGER)}>
              <i className="fa fa-paperclip" />
              CV Manager
            </NavLink>
          </li> */}
          {/* <li
            className={setMenuActive(currentpath, canRoute(candidate.ALERTS))}
          >
            <NavLink to={canRoute(candidate.ALERTS)}>
              <i className="fa fa-bell" />
              Job Alerts
            </NavLink>
          </li> */}
          {/* <li
            className={setMenuActive(
              currentpath,
              canRoute(candidate.CHANGE_PASSWORD)
            )}
          >
            <NavLink to={canRoute(candidate.CHANGE_PASSWORD)}>
              <i className="fa fa-fingerprint" />
              Change Passeord
            </NavLink>
          </li> */}
          {/* <li className={setMenuActive(currentpath, canRoute(candidate.CHAT))}>
            <NavLink to={canRoute(candidate.CHAT)}>
              <i className="fa fa-comments" />
              Chat
            </NavLink>
          </li> */}
        </ul>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default CanSidebarSection;
