import React, { useEffect, useState } from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SectionPagination from "../../sections/common/section-pagination";
import GetProfileImage from "../../../../common/getProfileImage";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import apiCall from "../../../../../apicall";

const EmpUsersList  = ({
      empDetailId,
      handleEmpUserListRes,
      isUserListRefreshed
    // empUsersList,
    // empUsersLoader,
    // rowUsersPerPage,
    // handleRecordsUsersPerPageChange,
    // paginationUsersData,
    // usersCurrentPage,
    // setUsersCurrentPage,
    // tableFilter,
    // setTableFilter,
}) => {
  // console.log(
  //   "paginationUsersData",
  //   paginationUsersData,empUsersList
  // );
  const { role_type , TOKEN } = useSelector((state) => state.menu);
  const [empUsersLoader, setEmpUsersLoader] = useState(false);
  const [empUsersInfo, setEmpUsersInfo] = useState({});
  const [rowUsersPerPage, setUsersRowPerPage] = useState(10);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [usersCurrentPage, setUsersCurrentPage] = useState(1);
  const [paginationUsersData, setPaginationUsersData] = useState({
       totalElements: 0,
       totalPages: 0,
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
      message: "",
      status: "error",
  });
  
  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    status: "",
    searchKey: "",
  });

  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: false,
    total: paginationUsersData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const StarRating = ({ rating }) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < rating) {
        stars.push(
          <i
            key={i}
            className="feather feather-star"
            style={{ color: "#128c5e" }}
          />
        );
      } else {
        stars.push(
          <i
            key={i}
            className="feather feather-star"
            style={{ color: "lightgrey" }}
          />
        );
      }
    }
    return stars;
  };

  const handleRecordsUsersPerPageChange = (event) => {
    setUsersCurrentPage(1);
    setUsersRowPerPage(event.target.value);
  };

  const displayEmpUsersErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setEmpUsersInfo({});
    setPaginationUsersData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const getEmpUsersData = async () => {
    try {
      setEmpUsersLoader(true);
      const api = `${
        role_type.includes("ROLE_OPENSTAFF_ADMIN")
          && `openstaff/institutions/${empDetailId}/users?offset=0&page=${
              usersCurrentPage - 1
            }&size=${rowUsersPerPage}`
      }`;
      const empUsersResponse = await apiCall(api, "GET", null, TOKEN);
      console.log("empUsersResponse---", empUsersResponse);

      if (empUsersResponse?.data.content?.length > 0) {
        setSessionExpired(false);
        setEmpUsersInfo(empUsersResponse.data.content);
        setPaginationUsersData({
          totalElements: empUsersResponse.data.totalElements,
          totalPages: empUsersResponse.data.totalPages,
        });
        handleEmpUserListRes(empUsersResponse.data.content, false);
      } else if (empUsersResponse?.data.content?.length == 0) {
        setEmpUsersInfo({});
        setPaginationUsersData({
          totalElements: 0,
          totalPages: 0,
        });
        handleEmpUserListRes({}, false)
      } else if (empUsersResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayEmpUsersErrMsg("Your session has been expired!!");
        handleEmpUserListRes({}, false)
      } else {
        setSessionExpired(false);
        // displayEmpUsersErrMsg("Something went wrong!");
        displayEmpUsersErrMsg(empUsersResponse?.response?.data?.message)
        handleEmpUserListRes({}, false)
      }
    } catch (err) {
      setSessionExpired(false);
      displayEmpUsersErrMsg("Something went wrong!");
      handleEmpUserListRes({}, false)
    }finally{
      setEmpUsersLoader(false)
    }
  };

  useEffect(() => {
    if(role_type.includes("ROLE_OPENSTAFF_ADMIN")){
       getEmpUsersData();
    }
  }, [usersCurrentPage, rowUsersPerPage, isUserListRefreshed]);
  return (
    <>
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={rowUsersPerPage}
        handleRecordsPerPageChange={handleRecordsUsersPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
        currentPage={usersCurrentPage}
      />
      <div className="twm-candidates-list-wrap">
        <ul>
          {!empUsersLoader ? (
            empUsersInfo?.length > 0 ? (
                empUsersInfo?.map((item) => (
                <li>
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-media">
                      <div className="twm-media-pic">
                        <GetProfileImage
                          id={item?.id}
                          type={"userAvatar"}
                        />
                      </div>
                      <div className="twm-candidates-tag">
                        <span>{item?.accountStatus || " -"}</span>
                      </div>
                    </div>
                    <div className="twm-mid-content">
                        <h4>{`${item?.institutionName}`}</h4>
                      <div className="row col-lg-12 col-sm-12">
                        <div className="col-lg-6 col-sm-6">
                          <p className="twm-candidate-address">
                            {item?.firstName} { item?.lastName}
                          </p>                       
                          <p className="twm-candidate-address">
                            {item?.email}
                          </p>                        
                        </div>
                        <div className="col-lg-6 col-sm-6">                       
                          <p className="twm-candidate-address">
                            <b>Role: </b>
                            {item?.roleEntities[0]?.roleType}
                          </p>  
                        </div>             
                      </div>
                      
                      {/* <div className="twm-fot-content"> */}
                      {/* <div className="twm-right-btn">
                            <span
                              // to={publicUser.candidate.DETAIL1}
                              className="twm-view-prifile site-text-primary"
                              onClick={() => navigateToApplicantDetails(item)}
                            >
                              View Profile
                            </span>
                          </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No results found."
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        <SectionPagination
          currentPage={usersCurrentPage}
          setCurrentPage={setUsersCurrentPage}
          totalPages={paginationUsersData?.totalPages}
        />
      </div>
    </>
  );
};

export default EmpUsersList;
