import { useEffect, useState, useRef } from "react";
import apiCall from "../../../../../apicall";
import { useSelector, useDispatch } from "react-redux";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import { useLocation } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../config";
import { loadScript } from "../../../../../globals/constants";
import { addressSelectedStatus } from "../../../../../store/reducers/menu";
import { cityListDetails } from "../../../../../store/reducers/menu";
import TableLoader from "../../../../common/tableLoader";
import ExportCSV from "../../components/jobs/export-csv";

function SectionJobsSearch({
  setTableFilter,
  tableFilter,
  setCurrentPage,
  applicantList,
  customerList,
  jobListData
}) {
  const { TOKEN, role_type , cityListData, isLoggedIn } = useSelector((state) => state.menu);
  const location = useLocation();
  const dispatch = useDispatch();
  const statusdropdownRef = useRef(null);
  const professionDropdownRef = useRef(null);
  const currentUrl = location.pathname;
  const [jobTypes, setJobTypes] = useState([]);
  const [isJobTypeLoading, setIsJobTypeLoading] = useState(false);
  const [cityList, setCityList] = useState(cityListData);
  const [searchedKey, setSearchedKey] = useState("");
  const [fromDateSelected, setFromDateSelected] = useState("");
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [professionDropdownOpen, setProfessionDropdownOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [searchCompany, setSearchCompany] = useState("");
  const [updatedLocationName, setUpdatedLocationName] = useState(
    tableFilter?.locationName ? tableFilter?.locationName : ""
  );
  const jobStatusMapping = {"Cancelled": "eCancelled", "Closed": "eClosed", "Completed": "eCompleted", "Deleted": "eDeleted", "InProgress": "eInProgress", "Open": "eOpen"};
  const jobStatuses = Object.keys(jobStatusMapping);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (statusdropdownRef?.current && !statusdropdownRef?.current.contains(event.target)) {
        setStatusDropdownOpen(false);
      }
      if (professionDropdownRef?.current && !professionDropdownRef?.current.contains(event.target)) {
        setProfessionDropdownOpen(false);
      }
    };
 
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])

  useEffect(() => {
    loadScript("js/custom.js");
  });

  const fetchCityList = async () => {
    const cityListResponse = await apiCall(
      "config/locationList",
      "GET",
      null,
      TOKEN
    );
    console.log("cityListResponse", cityListResponse);
    if (cityListResponse?.data?.length > 0) {
      // const cityListData = cityListResponse.data.map((item) => item.city);
      setCityList(cityListResponse?.data);
      dispatch(cityListDetails(cityListResponse?.data));
    }
  };

  useEffect(() => {
    if (cityListData?.length === 0 && !isLoggedIn) {
      fetchCityList();
    }
  }, []);

  useEffect(() => {
      const fetchData = async () => {
        setIsJobTypeLoading(true);
        const positionResponse = await apiCall(
          "config/allJobTypes",
          "GET",
          null,
          TOKEN
        );
        if (positionResponse?.data?.length > 0) {
          const jobTypesData = positionResponse.data.map(
            (item) => item.typeName
          );
          console.log("jobTypesData",jobTypesData)
          setJobTypes(jobTypesData);
          setIsJobTypeLoading(false);
        }else if (positionResponse?.data?.length === 0){
          setIsJobTypeLoading(false);
        }
    }
    fetchData();

  }, []);

  const handleDateChange = (date, fieldName) => {
    if (fieldName === "fromDate") {
      setFromDateSelected(moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]"));
    } else if (fieldName === "toDate") {
      setTableFilter({
        ...tableFilter,
        fromDate: fromDateSelected,
        toDate: moment(date).format("YYYY-MM-DDTHH:mm:ss[Z]"),
      });
      setCurrentPage(1);
    }
  };

  const handleApplicantChange = (event) => {
    setTableFilter({
      ...tableFilter,
      applicant: event.target.value,
    });
  };

  const handleCustomerChange = (event) => {
    setTableFilter({
      ...tableFilter,
      customer: event.target.value,
    });
    setCurrentPage(1);
  };

  // const handleJobTypeChange = (event) => {
  //   console.log("handleJobTypeChange", event);
  //   setTableFilter({
  //     ...tableFilter,
  //     categoryType: event.target.value,
  //   });
  //   setCurrentPage(1);
  // };

  const toggleStatusDropdown = () => {
    setStatusDropdownOpen((prev) => !prev);
    setProfessionDropdownOpen(false);
  };

  const toggleProfessionDropdown = () => {
    setProfessionDropdownOpen((prev) => !prev);
    setStatusDropdownOpen(false);
  };

  const handlePositionChange = (e, type) => {
    setSelectedTypes((prevSelected) => {
      const updatedSelected = e.target.checked
        ? [...prevSelected, type]
        : prevSelected.filter((item) => item !== type); 
  
      setTableFilter((prevFilter) => ({
        ...prevFilter,
        categoryType: updatedSelected == "" ? "All" : updatedSelected, 
      }));
  
      console.log("updatedSelected====",updatedSelected)
      return updatedSelected; 
    });
  
    setCurrentPage(1);
  };

  const handleSearchKeyChange = (e) => {
    setSearchedKey(e.target.value);
  };

  const handleSearchClick = () => {
    setTableFilter({
      ...tableFilter,
      searchKey: searchedKey,
    });
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    console.log("handleStatusChange-----", e.target.value);
    setTableFilter({
      ...tableFilter,
      jobStatus: e.target.value,
    });
    setCurrentPage(1);
  };

  const handleStatusSelection = (e, status) => {
   
    setSelectedStatuses((prevSelected) => {
      const updatedSelected = e.target.checked
        ? [...prevSelected, status]
        : prevSelected.includes(status) ? prevSelected.filter((s) => s !== status) : [...prevSelected, status]; 
  
      const apiStatuses = updatedSelected.map((status) => jobStatusMapping[status]);
      console.log("apiStatuses====",apiStatuses)
      setTableFilter((prevFilter) => ({
        ...prevFilter,
        jobStatus: apiStatuses.length === 0 ? "All" : apiStatuses, 
      }));
  
      console.log("updatedSelected====",updatedSelected)
      return updatedSelected; 
    });
    setCurrentPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleInputChange = (e) => {
    setUpdatedLocationName(e.target.value);
  };

  const handleCitySelect = (selectedJobType) => {
    const selectedCity = cityList.filter((val) => val.city === selectedJobType);
    setCurrentPage(1);
    if (selectedCity?.length > 0) {
      setTableFilter((prevValues) => ({
        ...prevValues,
        lat: selectedCity[0].lat,
        lng: selectedCity[0].lng,
        locationName: selectedCity[0].city,
      }));
    } else {
      setTableFilter((prevValues) => ({
        ...prevValues,
        lat: "",
        lng: "",
        locationName: "",
      }));
    }
  };

  const handlePlaceSelect = (place) => {
    if (place?.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setUpdatedLocationName(place?.formatted_address);
      setCurrentPage(1);
      setTableFilter({
        ...tableFilter,
        lat,
        lng,
        locationName: place?.formatted_address,
      });
      dispatch(
        addressSelectedStatus({
          latlng: { lat, lng },
          jobType: tableFilter?.jobType,
          locationName: place?.formatted_address,
        })
      );
    }
  };

  const handleCompanyKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchCompanyClick(e);
    }
  };

  const handleSearchCompanyChange = (e) => {
    const newSearchCompany = e.target.value;
    setSearchCompany(newSearchCompany);

    if (newSearchCompany.trim() === "") {
      setTableFilter({
        ...tableFilter,
        company: newSearchCompany,
      });
      setCurrentPage(1);
    }
  };

  const handleSearchCompanyClick = (e) => {
    e.preventDefault();
    setTableFilter({
      ...tableFilter,
      company: searchCompany,
    });
    setCurrentPage(1);
  };

  return (
    <>
      <form>
        {/*Search Bar*/}
        {/*Basic Information*/}
        <div className="panel panel-default">
          <div className="col-lg-12 col-md-12 col-sm-12 panel-heading wt-panel-heading p-a20 d-flex">
            <div className="col-lg-8 col-md-8 col-sm-8">
            <h4 className="panel-tittle m-a0">
              <i className="fa fa-suitcase" />
              Find Jobs
            </h4>
                </div>
                <ExportCSV jobListData={jobListData} />
          </div>
          <div className="panel-body wt-panel-body p-a20 m-b30 ">
            <div className="row">
              {/*Job title*/}
              {currentUrl === "/job-grid-with-map" &&
                (role_type?.length === 0 ||
                  role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && (
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Status</label>
                      <div className="ls-inputicon-box">
                        {/* <input
                      className="form-control"
                      name="company_name"
                      type="text"
                      placeholder="Types of Jobs"
                    />
                    <i className="fs-input-icon fa fa-address-card" /> */}
                        {/* <select
                          className="wt-select-bar-large selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={tableFilter?.jobStatus || ""}
                          onChange={handleStatusChange}
                        >
                          {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && (
                            <option value="All">All</option>
                          )}
                          <option value="eOpen">Open</option>

                          {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) ||
                            (role_type.includes("ROLE_USER") && (
                              <>
                                <option value="eAccepted">Accepted</option>
                                <option value="eApplied">Applied</option>
                              </>
                            ))}

                          {role_type.includes("ROLE_INSTITUTION_ADMIN") && (
                            <option value="eScheduled">Scheduled</option>
                          )}

                          {(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")) && (
                            <>
                              <option value="eCancelled">Cancelled</option>
                              <option value="eClosed">Closed</option>
                              <option value="eCompleted">Completed</option>
                              <option value="eCreated">Created</option>
                              <option value="eDeleted">Deleted</option>
                              <option value="eInProgress">InProgress</option>
                              <option value="eUnAvailable">UnAvailable</option>
                              <option value="eUnfulfilled">Unfulfilled</option>
                            </>
                          )}
                          {(role_type.includes("ROLE_USER") ||
                            role_type.includes("ROLE_INSTITUTION_ADMIN")) && (
                            <option value="eHistory">History</option>
                          )}
                        </select> */}
                          <div
                          className="custom-dropdown"
                          ref={statusdropdownRef}
                        >
                          <button
                            type="button"
                            className="btn dropdown-toggle form-control"
                            onClick={toggleStatusDropdown}
                            style={{
                              color: "black",
                              textAlign: "left",
                              height: "auto",
                              textWrap: "wrap",
                            }}
                          >
                            {selectedStatuses?.length > 0
                              ? selectedStatuses?.join(", ")
                              : "All"}
                          </button>
                          {statusDropdownOpen && (
                            <div
                              className={`dropdown-menu show`}
                              style={{ width: "80%" }}
                            >
                              {jobStatuses.map((status) => (
                                <div key={status} className="dropdown-item">
                                  <input
                                    type="checkbox"
                                    style={{ marginRight: 10 }}
                                    value={status}
                                    checked={selectedStatuses?.includes(status)}
                                    onChange={(e) =>
                                      handleStatusSelection(e, status)
                                    }
                                  />
                                  <label>{status}</label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/*Job Category*/}
              {currentUrl === "/users-list" &&
                role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group city-outer-bx has-feedback">
                      <label>Status</label>
                      <div className="ls-inputicon-box">
                        {/* <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title=""
                      id="j-category"
                      data-bv-field="size"
                    >
                      <option disabled>Select Category</option>
                      <option>Accounting and Finance</option>
                      <option>Clerical &amp; Data Entry</option>
                      <option>Counseling</option>
                      <option>Court Administration</option>
                      <option>Human Resources</option>
                      <option>Investigative</option>
                      <option>IT and Computers</option>
                      <option>Law Enforcement</option>
                      <option>Management</option>
                      <option>Miscellaneous</option>
                      <option>Public Relations</option>
                    </select> */}
                        <select
                          className="wt-select-bar-large selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={tableFilter?.userListStatus || ""}
                          onChange={handleStatusChange}
                        >
                          <option value="All">All</option>
                          <option value="INACTIVE">Inactive</option>
                          <option value="UNVERIFIED">Unverified</option>
                          <option value="PENDING_APPROVAL">
                            Pending Approval
                          </option>
                          <option value="ACTIVE">Active</option>
                          <option value="LOCKED">Locked</option>
                          <option value="SUSPENDED">Suspended</option>
                          <option value="DELETED">Deleted</option>
                          <option value="EXPIRED">Expired</option>
                        </select>
                        <i className="fs-input-icon fa fa-border-all" />
                      </div>
                    </div>
                  </div>
                )}

              <div className="col-xl-4 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Job Title</label>
                  <div className="ls-inputicon-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by job title"
                      value={searchedKey}
                      onChange={handleSearchKeyChange}
                      onKeyPress={handleKeyPress}
                    />
                    {/* <button className="btn" type="button">
                      <i
                        className="feather-search"
                        onClick={handleSearchClick}
                      /> 
                    </button>*/}
                  </div>
                </div>
              </div>
              {/*Job Type*/}
              {
                !isJobTypeLoading ? ( 
                // role_type !== "ROLE_USER" && (
                  <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Profession</label>
                      <div className="ls-inputicon-box">
                        {/* <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title=""
                      id="s-category"
                      data-bv-field="size"
                    >
                      <option className="bs-title-option" value>
                        Select Category
                      </option>
                      <option>Full Time</option>
                      <option>Freelance</option>
                      <option>Part Time</option>
                      <option>Internship</option>
                      <option>Temporary</option>
                    </select> */}
                        {/* <select
                          className="wt-select-bar-large selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={tableFilter?.jobTypes || ""}
                          onChange={handleJobTypeChange}
                        >
                          <option value="All">Search by profession(s)</option>
                          {jobTypes?.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                        <i className="fs-input-icon fa fa-file-alt" /> */}
                        <div className="custom-dropdown" ref={professionDropdownRef}>
                          <button
                            type="button"
                            className="btn dropdown-toggle form-control"
                            onClick={toggleProfessionDropdown}
                            style={{
                              color: "black",
                              textAlign: "left",
                              height: "auto",
                              textWrap: "wrap",
                            }}
                          >
                            {selectedTypes?.length > 0
                              ? selectedTypes?.join(", ")
                              : "Search by profession(s)"}
                          </button>
                          {professionDropdownOpen && (
                            <div
                              className="dropdown-menu show"
                              style={{ width: "80%" }}
                            >
                              {jobTypes?.map((type) => (
                                <div key={type} className="dropdown-item">
                                  <input
                                    type="checkbox"
                                    style={{ marginRight: 10 }}
                                    value={type}
                                    checked={selectedTypes?.includes(type)}
                                    onChange={(e) =>
                                      handlePositionChange(e, type)
                                    }
                                  />
                                  <label>{type}</label>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> 
                )
                  : (
                    jobTypes?.length === 0 ?
                    <TableLoader /> : ''
                  )}
              {/*Location*/}
              {
                isLoggedIn && 
                <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Location</label>
                  <div className="ls-inputicon-box">
                    {/* <input
                      className="form-control"
                      name="company_Email"
                      type="text"
                      placeholder="Type Address"
                    /> */}
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_API}
                      onPlaceSelected={handlePlaceSelect}
                      onChange={handleInputChange}
                      placeholder="Search..."
                      className="form-control"
                      types={["geocode"]}
                      options={{
                        types: ["address"],
                        bounds: {
                          north: parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),  
                          south: parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),  
                          east:  parseFloat(process.env.REACT_APP_LOC_EAST_BOUND), 
                          west:  parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),  
                        },
                        componentRestrictions: { country: "us" },
                      }}
                      value={updatedLocationName}
                    />
                    <i className="fs-input-icon fa fa-map-marker-alt" />
                  </div>
                </div>
              </div>
              }

              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group mb-4">
                  <label>Company</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by company"
                      value={searchCompany}
                      onChange={handleSearchCompanyChange}
                      onKeyPress={handleCompanyKeyPress}
                    />
                  </div>
                </div>
              </div>
            

              {cityList?.length > 0 && !isLoggedIn && (
              <div className="form-group mb-4 joblist-sidebar">
                <h4 className="section-head-small mb-4">Location</h4>
                <select
                  className="wt-select-bar-large selectpicker"
                  data-live-search="true"
                  data-bv-field="size"
                  defaultValue={tableFilter?.locationName}
                  onChange={(e) => handleCitySelect(e.target.value)}
                >
                  <option value="">Select Location</option>
                  {cityList?.map((type, index) => (
                    <option key={index} value={type.city}>
                      {type.city} ({type.adminName})
                    </option>
                  ))}
                </select>
              </div>
            )}
              {/*Salary*/}

              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Salary</label>
                  <div className="ls-inputicon-box">
                    <select
                      className="wt-select-box selectpicker"
                      data-live-search="true"
                      title=""
                      id="salary"
                      data-bv-field="size"
                    >
                      <option className="bs-title-option" value>
                        Salary
                      </option>
                      <option>$500</option>
                      <option>$1000</option>
                      <option>$1500</option>
                      <option>$2000</option>
                      <option>$2500</option>
                    </select>
                    <i className="fs-input-icon fa fa-dollar-sign" />
                  </div>
                </div>
              </div> */}

              {/* {currentUrl === "/job-grid-with-map" &&
                applicantList?.length > 0 && (
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Applicants</label>
                      <div className="ls-inputicon-box">
                        <select
                          className="wt-select-bar-large selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={tableFilter?.applicants || ""}
                          onChange={handleApplicantChange}
                        >
                          <option value="">Select Applicants</option>
                          {applicantList?.map((val, index) => (
                            <option key={index} value={val?.id}>
                              {`${val.firstName} ${val.lastName}`}
                            </option>
                          ))}
                        </select>
                        <i className="fs-input-icon fa fa-file-alt" />
                      </div>
                    </div>
                  </div>
                )}

              {currentUrl === "/job-grid-with-map" &&
                customerList?.length > 0 && (
                  <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <label>Customers</label>
                      <div className="ls-inputicon-box">
                        <select
                          className="wt-select-bar-large selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={tableFilter?.customer || ""}
                          onChange={handleCustomerChange}
                          style={{ display: "" }}
                        >
                          <option value="">Select Customers</option>
                          {customerList?.map((val, index) => (
                            <option key={index} value={val?.id}>
                              {`${val?.name}`}
                            </option>
                          ))}
                        </select>
                        <i className="fs-input-icon fa fa-file-alt" />
                      </div>
                    </div>
                  </div>
                )} */}

              {currentUrl === "/job-grid-with-map" &&
                role_type.includes("ROLE_OPENSTAFF_ADMIN") && (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Start Date</label>
                        <div className="ls-inputicon-box">
                          <ReactDatePicker
                            className="form-control"
                            selected={fromDateSelected}
                            onChange={(date) =>
                              handleDateChange(date, "fromDate")
                            }
                            dateFormat="MM-dd-yyyy"
                            placeholderText="MM/DD/YYYY"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>End Date</label>
                        <div className="ls-inputicon-box">
                          <ReactDatePicker
                            className="form-control"
                            selected={tableFilter.toDate}
                            onChange={(date) =>
                              handleDateChange(date, "toDate")
                            }
                            dateFormat="MM-dd-yyyy"
                            placeholderText="MM/DD/YYYY"
                            disabled={!fromDateSelected}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {/*Radius*/}
              {/* <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Radius</label>
                  <div className="twm-radius-range">
                    <b>10 Km</b>
                    <input
                      id="ex2"
                      type="text"
                      className="span2"
                      data-slider-min={10}
                      data-slider-max={100}
                      data-slider-step={5}
                      data-slider-value="[20,80]"
                    />
                    <b>100 Km</b>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-12 col-md-12">
                <div className="text-left">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => {
                      handleJobSearchFilter()
                      setCurrentPage(1);
                    }}
                  >
                    Search Job
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default SectionJobsSearch;
