import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../config";
import apiCall from "../../../../../apicall";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import SessionExpired from "../../../../common/sessionExpired";
import { deleteFirebaseUser, EmailVerification, insertFirebaseUser } from "../../../../common/register/firebase-email-verification";
import { navigateVisitedPage } from "../../../../../store/reducers/menu";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE), 
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE),
};

const AddUser = ( { institutionId, handleRefreshUserList} ) => {
    const { userInfo, CONFIGKEY, TOKEN, role_type } = useSelector((state)=>state.menu)
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
  const [positionDropdownList, setPositionDropdownList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [formValues, setFormValues] = useState({
    name: "",
    position: "",
    profession: "",
    // userName: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    suitNumber: "",
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const [privacyPolicyId, setPrivacyPolicyId] = useState("");
  const [termsAndConditionId, setTermsAndConditionId] = useState("");
  const [formErrors, setFormErrors] = useState({});

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  const handleInputChange = (e, fieldName) => {
    const { name, value } = e.target;
    let formattedValue = value;
  
    if (fieldName === "firstName" || fieldName === "lastName") {
      formattedValue = capitalizeFirstLetter(value);
    } 
    // else if (fieldName === "userName") {
    //   formattedValue = value.toLowerCase();
    // } 
    // else if (fieldName === "phoneNumber") {
    //   formattedValue = formatPhoneNumber(value);
    // }
  
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName || name]: formattedValue,
    }));
  
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName || name]: "",
    }));
  };
  const handleDateChange = (date, fieldName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));
  };
  const today = new Date();

  // Calculate the latest birth date for 18-year-olds
  const min18Date = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  const handlePlaceSelect = (place) => {
    // Update form values with selected place details
    if (place?.geometry) {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;

      const streetNumber = addressComponents.find(component =>
        component.types.includes("street_number")
    )?.long_name || "";

    console.log("location namemmm",streetNumber)


    const route = addressComponents.find(component =>
        component.types.includes("route")
    )?.long_name || "";

    const sublocality = addressComponents.find(component =>
        component.types.includes("sublocality_level_1") ||
        component.types.includes("locality") ||
        component.types.includes("neighborhood")
    )?.long_name || "";

      // const locationName = `${streetNumber} ${route}, ${sublocality}`;
      const locationName = `${streetNumber} ${route}`;
      // const locationName =
      //   addressComponents.find(
      //     (component) =>
      //       component.types.includes("point_of_interest") ||
      //       component.types.includes("sublocality") ||
      //       component.types.includes("neighborhood")
      //   )?.long_name || formattedAddress.split(",")[0];
      setFormValues((prevValues) => ({
        ...prevValues,
        address: formattedAddress,
        locationName: locationName.trim() || place.formatted_address,
        city:
          addressComponents.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          addressComponents.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name || "",
        country:
          addressComponents.find((component) =>
            component.types.includes("country")
          )?.long_name || "",
        zipCode:
          addressComponents.find((component) =>
            component.types.includes("postal_code")
          )?.short_name || "",
      }));
      // setAddressValue(formattedAddress); // Update the address value
    }
  };
  const validateForm = () => {
    const errors = {};

    // Validate fields common to both roles
    // if (!formValues.userName || formValues.userName.trim() === "") {
    //   errors.userName = "User Name is required.";
    // }

    if (!formValues.firstName || formValues.firstName.trim() === "") {
      errors.firstName = "First Name is required.";
    }

    if (!formValues.lastName || formValues.lastName.trim() === "") {
      errors.lastName = "Last Name is required.";
    }

    // if (!formValues.phoneNumber || formValues.phoneNumber.length !== 12) {
    //   errors.phoneNumber = "Phone number must be in the format 123-456-7890.";
    // }

    if (!formValues.email || formValues.email.trim() === "") {
      errors.email = "Email is required.";
    }

    // if (!formValues.address || formValues.address.trim() === "") {
    //   errors.address = "Address is required.";
    // }

    // Validate fields specific to ROLE_INSTITUTION_ADMIN
    // if (role_type === "ROLE_INSTITUTION_ADMIN") {
    //   if (!formValues.name || formValues.name.trim() === "") {
    //     errors.name = "Company Name is required.";
    //   }
      if (!formValues.position || formValues.position.trim() === "") {
        errors.position = "Position is required.";
      }

      // if (!formValues.paymentMethod || formValues.paymentMethod.trim() === "") {
      //   errors.paymentMethod = "Payment method type is required.";
      // }
    // }

    setFormErrors(errors);
    console.log("errors", errors);
    return Object.keys(errors).length === 0; // Return true if no errors, false if there are errors
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  // const handlePositionchange = (e) => {
  //   if (e.target.value !== "") {
  //     setSelectedType(e.target.value);
  //     const selectedPosition = positionDropdownList.find(
  //       (pos) => pos.typeName === e.target.value
  //     );
  //     const fileds = JSON.parse(selectedPosition.relatedFields);
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       profession: selectedPosition?.typeName,
  //     }));

  //     setSelectedFields(selectedPosition ? fileds.fields : []);
  //   }
  // };

  const displaySuccessMsg = () => {
    setIsLoading(false);
    setErrSuccessMsg({
      message: "User registered successfully!",
      status: true,
    });
    // alert("afterConfirm")
    setSelectedType("");
    setSelectedFields([]);
    setFormValues({
      name: "",
      position: "",
      // userName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      last4SSN: "",
      dob: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      profession: "",
      coverLetter: "",
      privacyPolicyId: "",
      termsAndConditionId: "",
      suitNumber: "",
    });
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const firebaseUID=await EmailVerification(formValues.email, userInfo);
    console.log('firebaseUID:poster::::::::::::::',firebaseUID)

    if (validateForm()) {
      // setIsLoading(false);
      if(firebaseUID?.uid){
      const formattedData = {
        ...formValues,
        // address: formValues?.address?.split(",")[0],
        // phoneType: "Mobile",
        // country: "US",
        firebaseId:firebaseUID?.uid,
      };
      try {
        const registerResponse = await apiCall(`openstaff/institutions/${institutionId}/addUser`, "POST", formattedData, TOKEN);
        console.log("registerResponse", registerResponse)
        if (registerResponse?.status === 200) {
          displaySuccessMsg();
          // const ConfirmationBackDrop = document.getElementById("modalBackdropregister");
          const ConfirmationPopupModal = document.getElementById(
            "add_user_popup"
          );
          formattedData["role_type"]="ROLE_INSTITUTION_ADMIN"
          insertFirebaseUser(formattedData, firebaseUID?.uid)
          .then(() => {
            console.log('User inserted successfully');
          })
          .catch((error) => {
            console.error('Error inserting user:', error);
          });
          if (ConfirmationPopupModal) {
            // ConfirmationBackDrop.style.zIndex = "1";
            // ConfirmationBackDrop.classList.add("show");
            ConfirmationPopupModal.classList.add("show");
            ConfirmationPopupModal.style.display = "block";
            // ConfirmationBackDrop.style.display = "block";
          }
          handleRefreshUserList()
        } 
        else if (registerResponse?.response?.status === 400) {
          displayErrMsg(registerResponse?.response?.data?.errorMessage);
          deleteFirebaseUser()
          setIsLoading(false);
        } else {
          // displayErrMsg("Something went wrong!");
          displayErrMsg(registerResponse?.response?.data?.message)
          deleteFirebaseUser()
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        displayErrMsg("Something went wrong!");
        deleteFirebaseUser()
        // console.error("Error:", error);
      }
    }
    else {
      setIsLoading(false);
      displayErrMsg(firebaseUID?.message);
      

      // console.log("Form validation failed.");
    }
  
  }
  else {
    setIsLoading(false);
    displayErrMsg("Check the form validation!");
    // console.log("Form validation failed.");
  }
  };

  // const getPositionDropdownList = async () => {
  //   try {
  //     setIsLoadingPositionList(true);
  //     const positionResponse = await apiCall("config/allJobTypes", "GET", null);
  //     if (
  //       positionResponse?.status === 200 &&
  //       positionResponse?.data?.length > 0
  //     ) {
  //       setIsLoadingPositionList(false);
  //       setPositionDropdownList(positionResponse?.data);
  //     } else {
  //       setIsLoadingPositionList(false);
  //       displayErrMsg("Somthing went wrong!");
  //     }
  //   } catch (err) {
  //     setIsLoadingPositionList(false);
  //     displayErrMsg("Somthing went wrong!");
  //   }
  // };

  useEffect(() => {
    // if(role_type === "ROLE_OPENSTAFF_ADMIN"){
    //   getPositionDropdownList();
    // }
    dispatch(navigateVisitedPage(null));
  }, []);
  const dropdownIconBoxStyle = {
    position: "relative",
  };

  const dropdownIconStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  };

  const dropdownPaddingRight = {
    paddingRight: "30px", // Adjust the padding to make room for the icon
  };
  const handleClearValues = () => {
    if (!formValues.privacyPolicyId) {
      setFormErrors((prevErrors) => ({
        address: "",
        email: "",
        firstName: "",
        lastName: "",
        position: "", 
      }));
    }
  };

  return (
    <div
      className="modal fade twm-sign-up"
      id="add_user_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Add User
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClearValues}
            />
          </div>
          <div className="modal-body">
          <form id="jobseeker_form">
        <div className="panel panel-default">
          <div className="panel-body wt-panel-body">
            <div className="row">
              {/* {role_type === "ROLE_INSTITUTION_ADMIN" && ( */}
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    {/* <label>Company Name</label> */}
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="name"
                        type="hidden"
                        placeholder="Company Name"
                        value={formValues.name}
                        onChange={(e) => handleInputChange(e, "name")}
                      />
                      {/* <i className="fs-input-icon fa fa-building " /> */}
                    </div>
                    {/* {formErrors.name && (
                      <span className="error-message">{formErrors.name}</span>
                    )} */}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  {/* <label>Phone</label> */}
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="phoneNumber"
                      type="hidden"
                      placeholder="Phone Number"
                      value={formValues.phoneNumber}
                      maxLength={12}
                      onChange={(e) => handleInputChange(e, "phoneNumber")}
                    />
                    {/* <i className="fs-input-icon fa fa-phone-alt" /> */}
                  </div>
                  {/* {formErrors.phoneNumber && (
                    <span className="error-message">
                      {formErrors.phoneNumber}
                    </span>
                  )} */}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>First Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="firstName"
                      type="text"
                      placeholder="First Name"
                      maxLength={20}
                      value={formValues.firstName}
                      onChange={(e) => handleInputChange(e, "firstName")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.firstName && (
                    <span className="error-message">
                      {formErrors.firstName}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="lastName"
                      type="text"
                      placeholder="Last Name"
                      maxLength={20}
                      value={formValues.lastName}
                      onChange={(e) => handleInputChange(e, "lastName")}
                    />
                    <i className="fs-input-icon fa fa-user " />
                  </div>
                  {formErrors.lastName && (
                    <span className="error-message">{formErrors.lastName}</span>
                  )}
                </div>
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="ls-inputicon-box">
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      placeholder="Email"
                      value={formValues.email}
                      onChange={(e) => handleInputChange(e, "email")}
                    />
                    <i className="fs-input-icon fas fa-at" />
                  </div>
                  {formErrors.email && (
                    <span className="error-message">{formErrors.email}</span>
                  )}
                </div>
              </div>
              {/* <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="form-group ">
                  <label>Address</label>
                  <div className="ls-inputicon-box">
                    <GooglePlacesAutocomplete
                      //ref={googlePlacesRef}
                      value={formValues.address}
                      onChange={(e) => handleInputChange(e, "address")}
                      onPlaceSelected={handlePlaceSelect}
                      className="form-control"
                      placeholder="Select for a location"
                      apiKey={process.env.REACT_APP_GOOGLE_API}
                      types={["geocode"]}
                      options={{
                        types: ["address"],
                        bounds: {
                          north: parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),  
                          south: parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),  
                          east:  parseFloat(process.env.REACT_APP_LOC_EAST_BOUND), 
                          west:  parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),  
                        },
                        componentRestrictions: { country: "us" },
                      }}
                    />
                    <i className="fs-input-icon fas fa-map-marker-alt" />
                  </div>
                  {formErrors.address && (
                    <span className="error-message">{formErrors.address}</span>
                  )}
                </div>
              </div> */}
              {formValues.address && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Location Name</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="locationName"
                        type="text"
                        placeholder="Location Name"
                        value={formValues.locationName}
                        onChange={(e) => handleInputChange(e, "locationName")}
                      />
                    </div>
                  </div>
                </div>
              )}
              {formValues.address && (
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Suite/Unit/Building</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="suitNumber"
                        type="text"
                        placeholder="Suite/Unit/Building"
                        value={formValues.suitNumber}
                        onChange={(e) => handleInputChange(e, "suitNumber")}
                      />
                    </div>
                  </div>
                </div>
              )}
              
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>Job Position</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="position"
                        type="text"
                        placeholder="Position"
                        value={formValues.position}
                        onChange={(e) => handleInputChange(e, "position")}
                      />
                      {/* <i className="fs-input-icon fas fa-map-marker-alt " /> */}
                    </div>
                    {formErrors.position && (
                      <span className="error-message">
                        {formErrors.position}
                      </span>
                    )}
                  </div>
                </div>            
              

              {selectedFields?.length > 0 &&
                selectedFields.map((field) => (
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div key={field.mapField} className="form-group">
                      <label>{field.title}</label>
                      <div className="ls-inputicon-box">
                        <input
                          type="text"
                          className="form-control"
                          name={field.mapField}
                          value={formValues[field.mapField] || ""}
                          onChange={(e) => handleInputChange(e, field.mapField)}
                          required={field.required}
                        />
                        {formErrors[field.mapField] && (
                          <span className="error-message">
                            {formErrors[field.mapField]}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

              <div className="col-lg-12 col-md-12">
                <div className="text-center">
                  <button
                    type="submit"
                    className="site-button"
                    onClick={handleRegisterSubmit}
                  >
                    {isLoading ? <Spinner /> : "Register"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
          </div>
          {/* <div className="modal-footer">
            <button className="btn btn-primary">
              {isLoading ? <Spinner /> : "Add Location"}
            </button>
          </div> */}
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default AddUser;
